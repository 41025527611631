import React from "react";
import {
  Button,
  Checkbox,
  ComboBox,
  Div,
  If,
  InputCep,
  InputDefault,
  InputInteger,
  ModalNotification,
  Row,
  Select,
  Title,
} from "../../components";
import { Label } from "recharts";
import { Color, Mask, Type, Utils } from "../../utilities";
import Axios from "axios";

const PATH_VIA_CEP = "https://viacep.com.br/ws/%s/json";

export default function AdicionarAlterarEndereco({
  enderecoEditar = {},
  getNomesEndereco = [],
  cadastrarEndereco,
  atualizarEndereco,
  cliente,
}) {
  const [erroLabel, setErroLabel] = React.useState("");
  const [enderecoAlterado, setEnderecoAlterado] = React.useState({
    descricao: "casa",
    descricaoAdicional: "",
    cep: "",
    idCidadeIbge: "",
    logradouro: "",
    numero: "",
    bairro: "",
    complemento: "",
    referencia: "",
    principal: false,
    ...enderecoEditar,
  });
  const [labelMensagemCepNaoEncontrado, setLabelMensagemCepNaoEncontrado] =
    React.useState("");

  function consultarCep(endereco) {
    Axios.get(PATH_VIA_CEP.replace("%s", Mask.clearMask(endereco.cep)))
      .then(({ data }) => {
        if (data) {
          console.log("t1", data);
          if (!Utils.isValueValid(data.erro)) {
            console.log("t2");
            setEnderecoAlterado({
              ...enderecoAlterado,
              idCidadeIbge: data.ibge,
              bairro: data.bairro,
              complemento: data.complemento,
              cidade: data.localidade,
              logradouro: data.logradouro,
            });
            setLabelMensagemCepNaoEncontrado("");
          } else {
            console.log("t3");
            setEnderecoAlterado({
              ...enderecoAlterado,
              idCidadeIbge: "",
              bairro: "",
              complemento: "",
              cidade: "",
              logradouro: "",
            });
            setLabelMensagemCepNaoEncontrado(
              "Endereço não encontrado para o CEP informado"
            );
          }
        } else {
          setEnderecoAlterado({
            ...enderecoAlterado,
            idCidadeIbge: "",
            bairro: "",
            complemento: "",
            cidade: "",
            logradouro: "",
          });
          setLabelMensagemCepNaoEncontrado(
            "Endereço não encontrado para o CEP informado"
          );
        }
      })
      .catch((error) => console.log(error));
  }

  function validarCadastro() {
    let endereco = enderecoAlterado;
    let erro = "";

    if (!Utils.isValueValid(endereco.numero))
      erro = "Preencha o campo numero do endereço de casa!";
    if (!Utils.isValueValid(endereco.descricaoAdicional))
      erro = "Preencha o campo para descrição";
    if (!Utils.isValueValid(endereco.bairro))
      erro = "Preencha o campo bairro do endereço de casa!";
    if (!Utils.isValueValid(endereco.logradouro))
      erro = "Preencha o campo lougradouro do endereço de casa!";
    if (!Mask.validarCep(endereco.cep)) erro = "Informe um CEP válido!";
    if (!Utils.isValueValid(endereco.descricao))
      erro = "Preencha o campo tipo do endereço!";
    if (!Utils.isValueValid(endereco.idCidadeIbge))
      erro = "Selecione a cidade!";

    let enderecos = Array.from(cliente.enderecos);
    let enderecoPrincipal = enderecos.filter((end) => end.principal)[0];

    if (!Utils.isValueValid(enderecoPrincipal) && !endereco.principal) {
      erro = "Nenhum endereço está marcado como principal";
    }

    setErroLabel(erro);

    return !Utils.isValueValid(erro);
  }

  return (
    <Div col="12" rounded column>
      <Div inline="start">
        <If and value1={erroLabel !== ""}>
          <Row col="12" inline="center">
            <Title
              value={erroLabel}
              type="h6"
              className="label-valor-total-carrinho text-danger"
              style={{ fontSize: "16px", margin: "0px 0px 40px 0px" }}
              family="Bold"
            />
          </Row>
        </If>
        <If and value1={Utils.isValueValid(labelMensagemCepNaoEncontrado)}>
          <Label
            col="12"
            value={labelMensagemCepNaoEncontrado}
            style={{ color: Color.ECOMMERCE.LARANJA }}
          />
        </If>
        <Select
          values={getNomesEndereco()}
          label="Tipo de endereço"
          valueSelected={enderecoAlterado.descricao}
          handlerChange={(e) => {
            setEnderecoAlterado({
              ...enderecoAlterado,
              descricao: e.target.value,
            });
            setErroLabel("");
          }}
        />

        <InputDefault
          handlerChange={(e) => {
            setErroLabel("");
            setEnderecoAlterado({
              ...enderecoAlterado,
              descricaoAdicional: e.target.value,
            });
          }}
          label="Descrição do Endereço"
          responsive="3"
          name="descricaoAdicional"
          value={enderecoAlterado.descricaoAdicional}
        />

        <InputCep
          handlerChange={(e) => {
            setLabelMensagemCepNaoEncontrado("Cep");
            setErroLabel("");
            setEnderecoAlterado({ ...enderecoAlterado, cep: e.target.value });
          }}
          handlerBlur={async () => {
            setLabelMensagemCepNaoEncontrado("");
            if (Mask.validarCep(enderecoAlterado.cep)) {
              setLabelMensagemCepNaoEncontrado("");
              consultarCep(enderecoAlterado);
            } else {
              let enderecoAlteradoNovo = {
                ...enderecoAlterado,
                logradouro: "",
                bairro: "",
              };
              setEnderecoAlterado({ ...enderecoAlteradoNovo });
            }
          }}
          label="CEP"
          responsive="3"
          name="cep"
          value={enderecoAlterado.cep}
        />
        <ComboBox
          label="Selecione uma cidade"
          responsive="3"
          labelPrimaryItem="Selecione"
          url="/cidade/estado/GO"
          name="idCidadeIbge"
          text="nomeCidade"
          valueSelected={enderecoAlterado.idCidadeIbge}
          handlerChange={(e) => {
            setErroLabel("");
            setEnderecoAlterado({
              ...enderecoAlterado,
              idCidadeIbge: e.target.value,
            });
          }}
        />
        <InputDefault
          handlerChange={(e) => {
            setErroLabel("");
            setEnderecoAlterado({
              ...enderecoAlterado,
              logradouro: e.target.value,
            });
          }}
          label="Logradouro"
          responsive="3"
          disabled={!Utils.isValueValid(labelMensagemCepNaoEncontrado)}
          name="logradouro"
          value={enderecoAlterado.logradouro}
        />
        <InputInteger
          handlerChange={(e) => {
            setErroLabel("");
            setEnderecoAlterado({
              ...enderecoAlterado,
              numero: e.target.value,
            });
          }}
          label="Número"
          responsive="2"
          name="numero"
          value={enderecoAlterado.numero}
        />
        <InputDefault
          handlerChange={(e) => {
            setErroLabel("");
            setEnderecoAlterado({
              ...enderecoAlterado,
              bairro: e.target.value,
            });
          }}
          disabled={!Utils.isValueValid(labelMensagemCepNaoEncontrado)}
          label="Bairro"
          responsive="3"
          name="bairro"
          value={enderecoAlterado.bairro}
        />
        <InputDefault
          handlerChange={(e) => {
            setErroLabel("");
            setEnderecoAlterado({
              ...enderecoAlterado,
              complemento: e.target.value,
            });
          }}
          label="Complemento"
          responsive="3"
          name="complemento"
          value={enderecoAlterado.complemento}
        />

        <InputDefault
          handlerChange={(e) => {
            setErroLabel("");
            setEnderecoAlterado({
              ...enderecoAlterado,
              referencia: e.target.value,
            });
          }}
          label="Referência"
          responsive="4"
          name="referencia"
          value={enderecoAlterado.referencia}
        />
        <Div col="12" inline="start">
          <Checkbox
            label="Esse é o endereço principal?"
            checked={enderecoAlterado.principal}
            handlerChange={({ checked }) => {
              setEnderecoAlterado({ ...enderecoAlterado, principal: checked });
            }}
            danger
          />
        </Div>
      </Div>
      <Button
        style={{
          backgroundColor: Color.EMPRESA.PRIMARIA,
          color: "white",
          padding: 10,
          marginBottom: 15,
          fontSize: 20,
          width: 350,
          borderRadius: 10,
          textAlign: "center",
        }}
        handlerClick={() => {
          if (validarCadastro()) {
            let enderecoNew = { ...enderecoAlterado };
            enderecoNew.cep = Mask.clearMask(enderecoNew.cep);
            enderecoNew.idCliente = cliente.idCliente;
            enderecoNew.descricao = enderecoNew.descricao + " - " + enderecoNew.descricaoAdicional

            if (Utils.isNotObjectEmpty(enderecoEditar)) {
              atualizarEndereco(enderecoNew);
            } else {
              cadastrarEndereco(enderecoNew);
            }
          }
        }}
      >
        {Utils.isNotObjectEmpty(enderecoEditar)
          ? "Atualizar Endereço"
          : "Adicionar Endereço"}
      </Button>
    </Div>
  );
}
