import React from "react";
import { Color, Mask, Type, Utils } from "../../../utilities";
import { Div, I, If, Label, Row } from "../../../components";

export default function RenderNossasLojasWeb({ empresa, setState, state }) {
  let filiais = Utils.isValueValid(empresa) ? empresa.filiais : [];
  const [filialSelected, setFilialSelected] = React.useState({
    urlGoogleMaps: null,
  });

  return (
    <Div
      className="scrollhost"
      style={{
        width: "100%",
        height: "100%",
        padding: 15,
      }}
    >
      <Div className="shadow" padding="2">
        <If and value1={!filialSelected.urlGoogleMaps}>
          <Div col="12" marginTop="1">
            <Label
              value="Para ver a localização click no endereço."
              family="SemiBold"
              style={{
                color: Color.EMPRESA.PRIMARIA,
                fontSize: 15,
                marginTop: 16,
              }}
            />
          </Div>
        </If>

        <If and value1={filialSelected.urlGoogleMaps}>
          <Row
            col="12"
            pointer
            padding="1"
            margin="1"
            handlerClick={() => {
              console.log("test");
              setFilialSelected({ urlGoogleMaps: null });
            }}
          >
            <Label
              value="Voltar"
              pointer
              family="SemiBold"
              style={{
                color: Color.ECOMMERCE.AZUL,
                fontSize: 15,
                marginTop: 16,
              }}
              handlerClick={() => {
                console.log("test");
                setFilialSelected({ urlGoogleMaps: null });
              }}
            />
          </Row>
        </If>

        <If
          and
          value1={Utils.isValueValid(filiais) && !filialSelected.urlGoogleMaps}
        >
          {Array.from(filiais).map((filial, index) => {
            return (
              <Div
                col="12"
                style={{
                  borderRadius: "8px",
                  padding: "25px 10px 15px",
                  margin: "0px 0px 15px",
                  border: "1px solid #dee2e6",
                }}
                className="shadow"
                handlerClick={() => setFilialSelected(filial)}
                pointer
              >
                <Label
                  value={filial.razaoSocial}
                  style={{
                    lineHeight: "15px",
                    fontSize: 14,
                    width: "100%",
                    color: Color.ECOMMERCE.CINZA_ESCURO,
                    textAlign: "justify",
                  }}
                  family="Bold"
                  //  className="text-ellipsis"
                  pointer
                />
                <Label
                  value={Mask.getValueMaskPhone(filial.telefone)}
                  style={{
                    lineHeight: "13px",
                    fontSize: 14,
                    width: "100%",
                    marginBottom: 15,
                    color: Color.ECOMMERCE.CINZA,
                    textAlign: "justify",
                  }}
                  pointer
                  family="Light"
                  // className="text-ellipsis"
                />
                <Label
                  value={`${filial.enderecoCompleto} - CEP: ${filial.cep}`}
                  style={{
                    lineHeight: "13px",
                    fontSize: 14,
                    width: "100%",
                    color: Color.ECOMMERCE.CINZA,
                    textAlign: "justify",
                  }}
                  pointer
                  family="Light"
                  //  className="text-ellipsis"
                />
              </Div>
            );
          })}
        </If>
        <If and value1={filialSelected.urlGoogleMaps}>
          <iframe
            title="Google Maps"
            src={filialSelected.urlGoogleMaps}
            width="100%"
            height={550}
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          />
        </If>
      </Div>
    </Div>
  );
}
