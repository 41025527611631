import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./App.css";
import { Div, If, Label, Parent } from "./components";
import { EndPoints, Http, Keys, Utils } from "./utilities";

import {
  atualizarCarrinho,
  setProdutos,
  setListasDeCompra,
  setEmpresa,
  setMenus,
  setAnuncios,
  setParametrosPedido,
} from "./redux/actions";
import Rodape from "./views/rodape/Rodape";
import HeaderLojaWeb from "./views/header/HeaderLojaWeb";
import TemplateTodosDepartamentos from "./views/departamentos/TemplateTodosDepartamentos";
import { DepartamentosLojaInstantanea } from "./views";
import PathImage from "./views/utils/PathImage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsuarioUtils from "./views/utils/UsuarioUtils";

const IS_LOJA_TESTE = window.location.hostname === "www.maiitre.com.br";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menus: [],
      abrirMenuDepartamentos: false,
      portrait: true,
    };

    window.outerWidth = 1158;

    this.atualizarPaginaNovaVersao();
  }

  atualizarPaginaNovaVersao() {
    let reloadPage = localStorage.getItem(Keys.KEY_RELOAD_PAGE);
    if (
      !Utils.isValueValid(reloadPage) ||
      String(reloadPage) !== Keys.KEY_VERSION_PAGE
    ) {
      localStorage.setItem(Keys.KEY_RELOAD_PAGE, Keys.KEY_VERSION_PAGE);
      localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
      localStorage.removeItem(Keys.KEY_USUARIO_LOJA_WEB);
      window.location.reload(true);
    }
  }

  componentDidMount() {
    this.listarMenus();
    this.consultarProdutos();
    this.consultarListasDeCompraCliente();
    this.consultarAnuncios();
    this.consultarParametrosPedido();
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.props.setParametrosPedido(data);
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  consultarAnuncios() {
    Http.get("oferta")
      .then(({ status, data }) => {
        if (status) {
          this.props.setAnuncios(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  consultarListasDeCompraCliente() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      Http.get(`listacompra/cliente/${cliente.idCliente}`)
        .then(({ status, data }) => {
          if (status) {
            Array.from(data).forEach((lista) => {
              Array.from(lista.itens).forEach((p) => {
                p.valorSubTotalItem =
                  Number(p.precoVenda) * Number(p.quantidade);
              });
            });
          }

          this.props.setListasDeCompra(data);
        })
        .catch((erro) => console.log(erro));
    } else {
      console.log(
        "Cliente não estão logado, não foi possível consultar as listas de compra"
      );
    }
  }

  consultarProdutos() {
    Http.get("produto")
      .then(({ status, data }) => {
        if (status) {
          let imagensIndisponiveis = [
            PathImage.IMAGEM_INDISPONIVEL,
            PathImage.IMAGEM_INDISPONIVEL_TUMB,
          ];
          let produtos = Array.from(data).filter(
            (p) => !imagensIndisponiveis.includes(p.urlImagemPrincipalThumbnail)
          );
          this.props.setProdutos(produtos);
        }
      })
      .catch((erro) => console.log(erro));
  }

  listarMenus() {
    Http.get(EndPoints.MENU)
      .then(({ status, data }) => {
        if (status) {
          this.props.setMenus(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  render() {

    return (
      <Parent>
        <ToastContainer />
        <If
          and
          value1={this.props.children.props.showHeader}
          value2={Utils.isValueValid(this.props.empresa)}
        >
          <HeaderLojaWeb
            empresa={this.props.empresa}
            menus={this.props.menus}
            history={this.props.history}
            handlerToggleDepartamentos={() =>
              this.setState({
                abrirMenuDepartamentos: !this.state.abrirMenuDepartamentos,
              })
            }
            handlerAbrirPesquisa={() => {
              this.setState({
                abrirMenuDepartamentos: false,
              });
            }}
          />
          {
            !this.props.children.props.showDepart && (
              <DepartamentosLojaInstantanea
                history={this.props.history}
                menus={this.props.menus}
                empresa={this.props.empresa}
                handlerToggleDepartamentos={() =>
                  this.setState({
                    abrirMenuDepartamentos: !this.state.abrirMenuDepartamentos,
                  })
                }
              />
            )
          }

          <Div>
            <If and value1={this.state.abrirMenuDepartamentos}>
              <Div
                style={{
                  height: "100%",
                  background: "black",
                  opacity: 0.5,
                  zIndex: 1002,
                  position: "fixed",
                  top: 0,
                  width: "100%",
                }}
                handlerClick={() =>
                  this.setState({
                    abrirMenuDepartamentos: !this.state.abrirMenuDepartamentos,
                  })
                }
              />
            </If>
            <TemplateTodosDepartamentos
              menus={this.props.menus}
              open={this.state.abrirMenuDepartamentos}
              history={this.props.history}
              empresa={this.props.empresa}
              handlerClose={() =>
                this.setState({ abrirMenuDepartamentos: false })
              }
            />
          </Div>
        </If>

        <Div inline="center" style={{ marginTop: "160px" }}>
          <If and value1={IS_LOJA_TESTE}>
            <Div
              style={{
                backgroundColor: "grey",
                color: "white",
                width: "100%",
                height: 100,
              }}
              inline="center"
            >
              <Label
                family="Bold"
                style={{ fontSize: 25 }}
                value="Esta é uma loja de demonstração para fins de teste - As compras realizadas não são válidas"
              />
            </Div>
          </If>

          {React.Children.map(this.props.children, (child, i) => {
            return child;
          })}
        </Div>

        <If
          and
          value1={this.props.children.props.showRodape}
          value2={Utils.isValueValid(this.props.empresa)}
        >
          <Rodape empresa={this.props.empresa} />
        </If>
      </Parent>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
    ...store.menusState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      atualizarCarrinho,
      setProdutos,
      setListasDeCompra,
      setEmpresa,
      setMenus,
      setAnuncios,
      setParametrosPedido,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
