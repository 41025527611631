import React from "react";
import { Component } from "react";
import { Div, I, If, Label, Row, Title, TooltipText } from "../../components";
import { Color, EndPoints, Http, Mask, Type, Utils } from "../../utilities";

import { ReactComponent as IconValor } from "../../assets/images/icon-valor.svg";
import { ReactComponent as IconEntrega } from "../../assets/images/icon-entrega.svg";

import { TemplateItensPedidoDetalhe } from "..";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
  },
  labelValorAlterado: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: "13px",
  },
  labelBorda: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    borderBottom: "1px dotted #E5E5E5",
  },
  labelAdicionarLista: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    borderRadius: 10,
    fontSize: 12,
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
  },
};

const stylesResumo = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textTransform: "uppercase",
    width: "100%",
    marginLeft: "5px",
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
  },
  taxaEntregaGratis: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: 13,
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
  },
  labelExcluirCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelBorda: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    borderBottom: "1px dotted #E5E5E5",
  },
  labelAdicionarLista: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    borderRadius: 10,
    fontSize: 12,
    marginTop: 5,
  },
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containerBotaoCancelar: {
    backgroundColor: Color.ECOMMERCE.LARANJA_CLARO,
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    margin: "30px 0px -20px",
    borderRadius: 4,
  },
  containerContinuar: {
    color: "white",
    padding: 12,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerBottom: {
    position: "fixed",
    bottom: 0,
    zIndex: 1002,
    width: "100%",
  },
  containerBottomMostrarTodos: {
    width: "100%",
  },
  containerLogoStockei: {
    padding: 15,
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
  },
};

class PedidoDetalheCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pedidoResumo: props.location.state.pedido,
      pedido: {},
    };
  }

  componentDidMount() {
    Http.get(
      EndPoints.PEDIDO.replace(
        "%s",
        this.state.pedidoResumo.idLancamentoPedidoApp
      )
    )
      .then(({ status, data }) => {
        if (status) {
          this.setState({ pedido: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getQuantidadeProdutos(pedido) {
    return Utils.isValueValid(pedido) ? Array.from(pedido.itens).length : 0;
  }

  getValorDescontoCupom(pedido) {
    return (
      Number(pedido.valorDescontoTaxaEntrega) +
      Number(pedido.valorDescontoCupom) +
      Number(pedido.valorDescontoTaxaServico)
    );
  }

  ResumoCompra() {
    const { pedido } = this.state;
    // let quantidadeItens = this.getQuantidadeProdutos(pedido);

    let alteradoNaSeparacao =
      Number(pedido.valorTotalSeparacao) > 0 &&
      Number(pedido.valorTotal).toFixed(2) !==
        Number(pedido.valorTotalSeparacao).toFixed(2);

    return (
      <Div
        inline="center"
        className="align-self-start"
        // responsive="4"
        marginTop="3"
        paddingBottom="5"
      >
        <Label
          value="Resumo da compra"
          style={stylesResumo.labelResumoCompra}
          family="SemiBold"
        />

        <Div className="w-100">
          <Div
            className="resumo-compra"
            style={{
              backgroundColor: "#FFF",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: "8px",
              padding: "20px",
            }}
          >
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Subtotal do carrinho"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={stylesResumo.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(pedido.valorTotalSemTaxa || 0)}
                  type="h6"
                  style={stylesResumo.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconEntrega style={{ width: "30px", margin: 2 }} />
              <Div className="d-flex flex-column justify-content-start">
                <Title
                  value="Taxa de entrega"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={stylesResumo.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(pedido.valorTaxaEntrega || 0)}
                  type="h6"
                  style={
                    pedido.valorTaxaEntrega
                      ? stylesResumo.taxaEntregaGratis
                      : stylesResumo.labelBlue
                  }
                  family="Bold"
                />
              </Div>
            </Div>

            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Valor taxa de serviço"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={stylesResumo.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(pedido.valorTaxaServico || 0)}
                  type="h6"
                  style={stylesResumo.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Desconto"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={stylesResumo.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(
                    this.getValorDescontoCupom(pedido) || 0
                  )}
                  type="h6"
                  style={{
                    ...stylesResumo.labelRed,
                    color: Color.EMPRESA.PRIMARIA,
                  }}
                  family="Bold"
                />
              </Div>
              <If and value1={Utils.isValueValid(pedido.codigoCupomDesconto)}>
                <TooltipText title="Cupom de desconto aplicado" direction="top">
                  <Label
                    value={`${pedido.codigoCupomDesconto}`}
                    family="Bold"
                    marginLeft="4"
                    style={{
                      fontSize: 12,
                      color: Color.ECOMMERCE.WHITE,
                      backgroundColor: Color.ECOMMERCE.LARANJA,
                      padding: 5,
                      borderRadius: 10,
                    }}
                  />
                </TooltipText>
              </If>
            </Div>
            <Div inline="start" className="border-top ">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Valor total do pedido"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={stylesResumo.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(pedido.valorTotal || 0)}
                  type="h6"
                  style={stylesResumo.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>

            <If and value1={alteradoNaSeparacao}>
              <Div inline="start" className="border-top " shadow>
                <IconValor style={{ width: "22px", margin: 2 }} />
                <Div
                  className="d-flex flex-column justify-content-start"
                  marginLeft="2"
                >
                  <Title
                    value="Total (Alterado na separação)"
                    type="h6"
                    className="label-valor-total-carrinho"
                    style={styles.labelCinza}
                    family="Bold"
                  />
                  <Title
                    value={Mask.formatValueBr(pedido.valorTotalSeparacao || 0)}
                    type="h6"
                    style={styles.labelBlue}
                    family="Bold"
                  />
                </Div>
              </Div>
            </If>
          </Div>
        </Div>
      </Div>
    );
  }

  BreadCrumbsPedidosDetalhes() {
    let stylesMobile = this.props.mobile
      ? { marginTop: 23, padding: "17px !important", marginBottom: 0, padding: "17px" }
      : { marginTop: -20, marginBottom: 20, paddingLeft: "3rem" };

    return (
      <Div className="w-100" style={{ marginTop: this.props.mobile ? -100 : 0 }}>
        <Row         
          style={{
            alignItems: "center",
            ...stylesMobile
          }}
        >
          <I
            icon={Type.ICON.HOME}
            pointer
            style={{ fontSize: 24, marginRight: 5, color: "#BABABA" }}
          />
          <Label
            value="Inicio"
            style={{ fontSize: 16, color: "#BABABA" }}
            family="Light"
            pointer
            margin="2"
            handlerClick={() => this.props.history.push("/")}
          />
          <Label
            value=">"
            style={{ fontSize: 16, color: "rgb(54, 162, 235)" }}
            margin="2"
          />

          <Label
            value="Pedidos"
            style={{ fontSize: 16, color: "#BABABA" }}
            family="Light"
            pointer
            margin="2"
            handlerClick={() => this.props.history.push("/cliente/pedidos")}
          />
          <Label
            value=">"
            style={{ fontSize: 16, color: "rgb(54, 162, 235)" }}
            margin="2"
          />

          <Label
            value={this.state.pedidoResumo.referencia}
            family="bold"
            style={{ fontSize: 16, color: "#373646" }}
            margin="2"
          />
        </Row>
      </Div>
    );
  }

  render() {
    return (
      <Row
        col="12"
        // inline="between"
        style={{
          display: "flex",
          alignItems: "flex-start !important",
          justifyContent: "space-around",
          marginTop: this.props.mobile && 100,
        }}
      >
        {this.BreadCrumbsPedidosDetalhes()}
        <TemplateItensPedidoDetalhe
          pedido={this.state.pedido}
          parametros={this.props.parametros}
          mobile={this.props.mobile}
        />
        <Div
          responsive="4"
          marginBottom="4"
          style={{
            maxHeight: 490,
            maxWidth: "380px",
            backgroundColor: "rgb(231, 232, 234)",
            borderRadius: "8px",
            marginBottom: this.props.mobile ? "37px !important" : "0",
          }}
        >
          {this.ResumoCompra()}
        </Div>
      </Row>
      // {this.renderResumoDaCompra()}
    );
  }
}

export default PedidoDetalheCliente;
