import React from "react";
import { Div, If, Title } from "../../components";
import { Color, Mask, Utils } from "../../utilities";
import { Label } from "recharts";

import { ReactComponent as IconValor } from "../../assets/images/icon-valor.svg";
import { ReactComponent as IconEntrega } from "../../assets/images/icon-entrega.svg";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textTransform: "uppercase",
    width: "100%",
    marginLeft: "5px",
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
  },
  taxaEntregaGratis: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: 13,
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
  },

  containerBotaoCancelar: {
    backgroundColor: Color.ECOMMERCE.LARANJA_CLARO,
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    margin: "30px 0px -20px",
    borderRadius: 4,
  },

  labelPedido: {
    color: Color.EMPRESA.PRIMARIA,
    textAlign: "center",
  },
  labelPedidoCancelado: {
    color: "red",
    textAlign: "center",
    textDecoration: "line-through",
  },
  labelPagamentoConfirmado: {
    color: "#31CDFF",
    fontSize: 15,
    lineHeight: "18px",
    width: "100%",
  },
  labelPagamentoAguardar: {
    color: Color.EMPRESA.PRIMARIA,
    fontSize: 16,
    lineHeight: "18px",
    width: "100%",
  },
};

export default function TemplatePedidos({
  pedido,
  styleLabelPedido,
  status,
  referencia,
}) {
  return (
    <Div
      inline="center"
      className="align-self-start"
      responsive="4"
      marginTop="3"
      paddingBottom="3"
    >
      <Div className="w-100">
        <Div
          className="resumo-compra"
          style={{
            backgroundColor: "#FFF",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            borderRadius: "8px",
            padding: "20px 30px",
          }}
        >
          <Div inline="start">
            <Div className="d-flex flex-column justify-content-start">
              <div style={styleLabelPedido}>{`Pedido ${Utils.capitalize(
                status
              )}`}</div>

              <Title
                value="Status pagamento"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={pedido.pago ? "Confirmado" : "Aguardando pagamento"}
                style={
                  pedido.pago
                    ? styles.labelPagamentoConfirmado
                    : styles.labelPagamentoAguardar
                }
                className="text-ellipsis"
                family="SemiBold"
              />

              <Title
                value={`Referência: ${referencia}`}
                type="h6"
                className="label-valor-total-carrinho"
                style={{
                  color: "#878787",
                  fontSize: 18,
                  margin: "10px 0 15px",
                }}
                family="Bold"
              />
            </Div>
          </Div>
          <Div inline="start">
            <IconEntrega style={{ width: "30px", margin: "2px 6px 2px 0px" }} />
            <Div className="d-flex flex-column justify-content-start">
              <Title
                value="Taxa de entrega"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={Mask.formatValueBr(pedido.valorTaxaEntrega)}
                type="h6"
                style={styles.labelBlue}
                family="Bold"
              />
            </Div>
          </Div>

          <Div
            className="border-top "
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start !important",
            }}
          >
            <IconValor style={{ width: "22px", margin: "10 2" }} />
            <Div
              className="d-flex flex-column justify-content-start"
              marginLeft="2"
            >
              <Title
                value="Valor total do pedido"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
              <Title
                value={Mask.formatValueBr(pedido.valorTotal)}
                type="h6"
                style={styles.labelBlue}
                family="Bold"
              />
              <If and value1={pedido.alteradoNaSeparacao}>
                <Title
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                  value="(Alterado na separação)"
                />
                <Title
                  value={Mask.formatValueBr(pedido.valorTotalSeparacao)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </If>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}
