import React from "react";
import { I, Label, ModalNotification, Row } from "../../../components";
import { Color, Type, Utils } from "../../../utilities";
import RenderNossasLojas from "../mobile/RenderNossasLojas";
import RenderNossasLojasWeb from "./RenderNossasLojasWeb";

const style = {
  titulo: {
    color: Color.EMPRESA.PRIMARIA,
    fontSize: "14px",
    marginBottom: "0",
  },
  separador: {
    margin: "0 15px",
    fontSize: "14px",
    color: Color.EMPRESA.PRIMARIA,
  },
};

export default function HeaderBarEmpresa({
  renderConversarWhatsapp,
  politicaEmpresa,
  sobreEmpresa,
  empresa,
  urlGoogleMaps
}) {
  const [state, setState] = React.useState({});

  const renderModal = (label, html) =>
    ModalNotification.render(
      label,
      <div dangerouslySetInnerHTML={{ __html: html }} />
    );

  const renderModalLojas = () =>
    ModalNotification.render(
      "Nossas lojas",
      <RenderNossasLojasWeb
        urlGoogleMaps={urlGoogleMaps}
        empresa={empresa}
        state={state}
        setState={(newState) => setState({ ...state, ...newState })}
      />
    );

  return (
    <Row col="12" padding="0" margin="0" marginTop="2" style={{ zIndex: 9999 }}>
      <Label
        className="hoverLinksHeader"
        handlerClick={() => {
          if (Utils.isNotNull(sobreEmpresa)) {
            renderModal("Sobre a empresa", sobreEmpresa);
          }
        }}
        pointer
        style={style.titulo}
      >
        Sobre a empresa
      </Label>
      <Label style={style.separador}> | </Label>
      <Label
        className="hoverLinksHeader"
        handlerClick={() => {
          if (Utils.isNotNull(politicaEmpresa)) {
            renderModal("Politca e regulamentos", politicaEmpresa);
          }
        }}
        pointer
        style={style.titulo}
      >
        Políticas e regulamentos
      </Label>
      <Label style={style.separador}> | </Label>
      <Label
        className="hoverLinksHeader"
        handlerClick={() => {
          if (Utils.isNotNull(empresa)) {
            renderModalLojas()
          }
        }}
        pointer
        style={style.titulo}
      >
        Nossas Lojas
      </Label>
      <Label style={style.separador}> | </Label>
      <Label
        className="hoverLinksHeader"
        handlerClick={() => renderConversarWhatsapp()}
        pointer
        style={style.titulo}
      >
        <I
          icon={Type.ICON.QUESTION_CIRLE}
          sizeIcon="1"
          colorText={Color.EMPRESA.PRIMARIA}
        />
        <span style={{ marginLeft: 5 }}>Ajuda</span>
      </Label>
    </Row>
  );
}
