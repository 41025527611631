import React, { Component } from "react";
import { Div, Image, ModalNotification } from "../components";
import TextAreaWithState from "../components/forms/textarea/TextAreaWithState";
import IconeWhatsapp from "../assets/images/icones/icon-whatsapp.png";
import { Color, Mask, Utils } from "../utilities";
import { EmpresaUtils } from ".";


const initContatoWhatsapp = {
  telefone: "",
  mensagem: "Oi, vim do site e gostaria de saber mais sobre os produtos!",
};

export default class ContatoWhatsapp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contatoWhatsapp: {
        telefone: "",
        mensagem: "Oi, vim do site e gostaria de saber mais sobre os produtos!",
      },
    };
  }

  enviarMensagemWhatsapp() {
    const { contatoWhatsapp } = this.state;
    let options = `top=${window.innerHeight / 4},left=${
      window.innerWidth / 4
    },location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=0`;

    let empresa = this.props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    window.open(
      `https://web.whatsapp.com/send?phone=55${Mask.clearMask(
        parametros.telefoneWhatsapp
      )}&text=${contatoWhatsapp.mensagem}`,
      "self",
      options
    );

    Utils.refreshPage();
  }

  renderConversarWhatsapp() {
    const { contatoWhatsapp } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa)
    ModalNotification.renderMessage({
      title: "Em caso de dúvidas, entre em contato conosco",
      body: (
        <Div col="8">
          <TextAreaWithState
            label="Mensagem"
            rows="5"
            value={contatoWhatsapp.mensagem}
            handlerChange={(mensagem) => {
              this.setState({
                contatoWhatsapp: {
                  ...contatoWhatsapp,
                  mensagem,
                },
              });
            }}
          />
        </Div>
      ),
      responsive: "6",
      handlerConfirm: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
        this.enviarMensagemWhatsapp();
        this.forceUpdate();
      },
      handlerCancel: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
      },
      labelButtonConfirm: "Enviar mensagem",
      styleButtonConfirm: {
        backgroundColor: corPrimaria ? corPrimaria : Color.ECOMMERCE.LARANJA
      }
    });
  }

  render() {
    return (
      <Image
        src={IconeWhatsapp}
        style={{
          position: "fixed",
          bottom: 15,
          right: 15,
          backgroundColor: "#25D366",
          padding: 15,
          borderRadius: 50,
          zIndex: this.props.zIndex || 1001,
        }}
        handlerClick={() => this.renderConversarWhatsapp()}
        pointer
      />
    );
  }
}
