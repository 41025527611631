import React, { Component } from "react";
import { I, If, Label } from "..";
import { Color, Type, Utils } from "../../utilities";
import Div from "../layout/div/Div";

import "./carousel.css";
import "./carouselprodutogenerico.css";

const styles = {
  labelDepartamento: {
    fontStyle: "normal",
    fontFamily: "MetropolisLight",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#878787",
    textTransform: "uppercase",
  },
  iconLabel: {
    color: Color.EMPRESA.PRIMARIA,
  },
  imageIcon: {
    width: 28,
    height: 28,
  },
  iconMaisDepartamentos: {
    color: Color.ECOMMERCE.LARANJA,
  },
};

class CarouselDepartamentosToppo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itens: props.itens || [],
    };
  }

  getClassNameButtonNext() {
    let classe = "carousel-control-next ";

    classe = classe.concat("icon-nex-carousel-produto-generico");

    return classe;
  }

  componentWillReceiveProps({ itens }) {
    this.setState({ itens });
  }

  render() {
    const ID_CAROUSEL = Utils.uuidString();

    let itensRender = Utils.quebrarArray(
      Array.from(this.state.itens),
      this.props.slidesPerPage
    );

    let sizeCarousel =
      Array.from(this.state.itens)
        .slice(1, this.props.slidesPerPage)
        .map((i) => i.width)
        .reduce((acc, val) => acc + val, 0) + 270;

    return (
      <Div
        style={{
          position: "fixed",
          width: "100%",
          top: 128,
          backgroundColor: "white",
          zIndex: 1001,
          borderBottom: `2px solid #f2f2f2`,
        }}
        inline="center"
      >
        <Div inline="start">
          <Div
            marginBottom="1"
            handlerClick={() => {
              this.props.handlerToggleDepartamentos();
              Utils.scrollTop();
            }}
            padding="2"
            marginTop="2"
            toggle="collapse"
            target={`#todosDepartamentosLojaWeb`}
            pointer
          >
            <Label
              value="Departamentos"
              style={styles.labelDepartamento}
              bold
              family="Light"
              pointer
            />
            <I
              marginLeft="1"
              marginBottom="2"
              icon={Type.ICON.ARROW_ANGLE_DOWN}
              style={styles.iconLabel}
            />
          </Div>
          <If and value1={Utils.isValueValid(this.props.itemDestaque)}>
            {this.props.itemDestaque}
          </If>
        </Div>

        <Div inline="center">
          <If and value1={this.props.showArrows}>
            <a
              href={`#${ID_CAROUSEL}`}
              role="button"
              data-slide="prev"
              className="cursor-pointer"
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <i
                style={{ fontSize: 40, color: "#C4C4C4" }}
                className={Type.ICON.ARROW_ANGLE_LEFT}
              />
            </a>
          </If>
          <Div
            className="carousel slide"
            id={ID_CAROUSEL}
            dataRide="carousel"
            dataInterval={false}
            style={{ width: sizeCarousel }}
          >
            <Div className="carousel-inner">
              {itensRender.map((itens, index) => {
                return (
                  <Div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <Div
                      inline={
                        Array.from(itens).length === this.props.slidesPerPage
                          ? "between"
                          : "start"
                      }
                    >
                      {Array.from(itens).map((item, index) => {
                        return (
                          <Div
                            inline="start"
                            key={index}
                            // style={{ width: item.width }}
                          >
                            {item.value}
                          </Div>
                        );
                      })}
                    </Div>
                  </Div>
                );
              })}
            </Div>
          </Div>
          <If and value1={this.props.showArrows}>
            <a
              href={`#${ID_CAROUSEL}`}
              role="button"
              data-slide="next"
              className="cursor-pointer"
            >
              <i
                style={{ fontSize: 40, color: "#C4C4C4" }}
                className={Type.ICON.ARROW_ANGLE_RIGHT}
              />
            </a>
          </If>
        </Div>
      </Div>
    );
  }
}

export default CarouselDepartamentosToppo;
