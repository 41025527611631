import React, { Component } from "react";

import Sidebar from "react-sidebar";
import {
  I,
  Image,
  Label,
  Div,
  If,
  InputSearchLojaInstantanea,
  ModalNotification,
  RadioCustom,
} from "../../../components";

import IconWhatsapp from "../../../assets/images/icones/icon-whatsapp.png";
import IconMensagem from "../../../assets/images/icones/icon-mensagem.png";
import { ReactComponent as IconMenuLeft } from "../../../assets/images/icon-menu-esquerdo.svg";
import IconApple from "../../../assets/images/icones/icon-apple.png";
import IconGooglePlay from "../../../assets/images/icones/icon-googleplay.png";
import IconFacebook from "../../../assets/images/icones/icon-facebook.png";
import IconInstagram from "../../../assets/images/icones/icon-instagram.png";

import IconArrowRight from "../../../assets/images/icones/icon-arrow-right.png";
import { ReactComponent as IconeLojaHome } from "../../../assets/images/icon-loja-home.svg";

import {
  Color,
  EndPoints,
  Http,
  Mask,
  Routes,
  Type,
  Utils,
} from "../../../utilities";
import Carrinho from "../../../views/carrinho/Carrinho";
import { connect } from "react-redux";

import "../header.css";
import MenuCliente from "../../../views/cliente/MenuCliente";
import { EmpresaUtils, LoginClienteMenu } from "../../../views";
import UsuarioUtils from "../../../views/utils/UsuarioUtils";
import Zindex from "../../../views/utils/Zindex";
import TextAreaWithState from "../../../components/forms/textarea/TextAreaWithState";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import { bindActionCreators } from "redux";
import { setEnderecoEntrega } from "../../../redux/actions";
import { toast } from "react-toastify";
import HeaderBarEmpresa from "./HeaderBarEmpresa";

const ID_INPUT_SEARCH = Utils.uuidString();

const styles = {
  menuCarrinho: {
    borderRadius: 5,
    marginTop: 20,
    marginRight: 15,
    color: "white",
    padding: 10,
    width: "40px",
  },
  labelValorTotalCarrinho: {
    color: Color.EMPRESA.TERCIARIA,
  },
  labelSAC: {
    fontSize: 25,
  },
  labelServicoAtendimento: {
    width: 150,
    fontSize: 18,
    marginTop: 4,
  },
  labelTelefone: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 25,
  },
  labelEmail: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 18,
  },
  labelQuantidadeTotalItens: {
    position: "absolute",
    transform: `translate(-10px, 45px)`,
    width: "30px",
    backgroundColor: Color.EMPRESA.TERCIARIA,
    color: "white",
    borderRadius: "50%",
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
  btnRegistrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: "#878787",
  },
  btnEntrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
  },
};

const initContatoWhatsapp = {
  telefone: "",
  mensagem: "",
};

class HeaderNavegacaoWeb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      isSideBarMenuLeft: true,
      propsSideBar: {},
      carrinho: props.carrinho,
      abrirNossasLojas: false,
      abrirMapa: false,
      abrirMenuUsuario: false,
      mostrarMenuUsuario: false,
      realizarLogin: false,
      abrirSobreEmpresa: false,
      abrirPoliticaRegulamento: false,
      sobreEmpresa: {},
      politicaEmpresa: {},
      contatoWhatsapp: initContatoWhatsapp,
      filialMapa: {},
      imagemLogo: "",
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.renderConversarWhatsapp = this.renderConversarWhatsapp.bind(this);
    this.renderTemplateSobrePolitica =
      this.renderTemplateSobrePolitica.bind(this);
  }

  componentDidMount() {
    const { empresa } = this.props;
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    this.setState({
      propsSideBar: this.getPropsSideBarMenuLeft(),
      imagemLogo: `${parametros.urlFoto}?${Utils.uuidString()}`,
    });
    this.consultarPoliticaEmpresa();
    this.consultarSobreEmpresa();
    this.onSetSidebarOpen(false);
  }

  componentWillReceiveProps({ textoPesquisa }) {
    this.setState({ textoPesquisa });
  }

  onSetSidebarOpen(open) {
    this.setState(
      open
        ? { sidebarOpen: open }
        : { sidebarOpen: open, abrirMenuUsuario: false },
      () => {
        if (open) {
          Utils.toggleScrollBody("hidden");
        } else {
          Utils.toggleScrollBody("auto");
        }
      }
    );
  }

  consultarPoliticaEmpresa() {
    Http.get(EndPoints.EMPRESA_POLITICA).then(({ status, data }) => {
      if (status) {
        this.setState({ politicaEmpresa: data.politica });
      } else {
        console.log("Falha ao consultar política da empresa");
      }
    });
  }

  consultarSobreEmpresa() {
    Http.get(EndPoints.EMPRESA_SOBRE).then(({ status, data }) => {
      if (status) {
        this.setState({ sobreEmpresa: data.sobre });
      } else {
        console.log("Falha ao consultar sobre da empresa");
      }
    });
  }

  renderMapaLoja() {
    let { urlGoogleMaps } = this.state.filialMapa;

    return (
      <Div
        className="scrollhost"
        style={{
          background: "#f2f2f2",
          width: "60%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MAPA_LOJA,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
          padding: 50,
        }}
      >
        <Div
          inline="end"
          handlerClick={() => this.setState({ abrirMapa: false })}
        >
          <I
            icon={Type.ICON.CLOSEX}
            sizeIcon="2"
            colorText={Color.NODE.DANGER}
          />
        </Div>
        <iframe
          src={urlGoogleMaps}
          width="100%"
          height="600"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        />
      </Div>
    );
  }

  renderTemplateNossasLojas = () => {
    let empresa = this.props.empresa || {};
    let filiais = Utils.isValueValid(empresa) ? empresa.filiais : [];

    return (
      <Div
        className="scrollhost"
        style={{
          background: "#f2f2f2",
          width: "60%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.TEMPLATE_NOSSAS_LOJAS,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
          padding: 50,
        }}
      >
        <I
          icon={Type.ICON.CLOSEX}
          sizeIcon="2"
          colorText={Color.NODE.DANGER}
          handlerClick={() => this.setState({ abrirNossasLojas: false })}
          style={{ position: "absolute", top: 15, right: 15 }}
          pointer
        />
        <Label
          value="Clique em cada endereço para abrir a localização no mapa."
          family="SemiBold"
          style={{ color: Color.EMPRESA.PRIMARIA, fontSize: 20 }}
        />
        <If and value1={Utils.isValueValid(filiais)}>
          {Array.from(filiais).map((filial, index) => {
            return (
              <Div
                className="d-flex flex-column justify-content-start align-items-start shadow p-1"
                handlerClick={() =>
                  this.setState({
                    abrirMapa: !this.state.abrirMapa,
                    filialMapa: filial,
                  })
                }
                key={index}
                pointer
                marginTop="3"
              >
                <Label
                  value={filial.razaoSocial}
                  pointer
                  family="Bold"
                  style={{
                    color: Color.ECOMMERCE.CINZA_ESCURO,
                    lineHeight: "15px",
                  }}
                />
                <Label
                  value={Mask.getValueMaskPhone(filial.telefone)}
                  pointer
                  family="SemiBold"
                  style={{ color: Color.ECOMMERCE.CINZA, lineHeight: "10px" }}
                />
                <Label
                  value={`${filial.enderecoCompleto} - CEP: ${filial.cep}`}
                  pointer
                  family="Light"
                  style={{ color: Color.ECOMMERCE.CINZA, lineHeight: "10px" }}
                />
              </Div>
            );
          })}
        </If>
      </Div>
    );
  };

  renderTemplateMenuLeft = () => {
    let empresa = this.props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    let linkWhatsApp = `https://wa.me/55${String(
      parametros.telefoneWhatsapp
    ).replace(" ", "")}`;

    let horarioFuncionamento = [];
    if (Utils.isValueValid(parametros)) {
      horarioFuncionamento = String(parametros.horarioFuncionamento).split(
        "</br>"
      );
    }

    return (
      <Div className="scrollhost">
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
          paddingTop="4"
          inline="start"
        >
          <Image marginLeft="3" src={this.state.imagemLogo} width="150px" />
          <Div
            style={{ marginTop: 50 }}
            col="12"
            className="d-flex flex-column justify-content-stat align-items-start"
          >
            <Label
              value="Sobre a empresa"
              family="SemiBold"
              style={{ fontSize: 20, color: "#C4C4C4" }}
              pointer
              handlerClick={() =>
                this.setState({
                  abrirSobreEmpresa: true,
                  abrirPoliticaRegulamento: false,
                  abrirNossasLojas: false,
                })
              }
            />
            <Label
              value="Políticas e regulamentos"
              family="SemiBold"
              style={{ fontSize: 20, color: "#C4C4C4" }}
              pointer
              handlerClick={() =>
                this.setState({
                  abrirPoliticaRegulamento:
                    !this.state.abrirPoliticaRegulamento,
                  abrirSobreEmpresa: false,
                  abrirNossasLojas: false,
                })
              }
            />
          </Div>
        </Div>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
          className="pt-2 pb-2 border-top border-bottom"
          handlerClick={() =>
            this.setState({
              abrirNossasLojas: !this.state.abrirNossasLojas,
              abrirMapa: false,
              abrirPoliticaRegulamento: false,
              abrirSobreEmpresa: false,
            })
          }
        >
          <Div
            inline="between"
            col="12"
            style={{ paddingRight: "10%" }}
            pointer
          >
            <Div inline="start" pointer>
              <IconeLojaHome />
              <Label
                value="Nossas lojas"
                margin="3"
                pointer
                family="Bold"
                style={{ color: Color.ECOMMERCE.MARRON_ESCURO }}
              />
            </Div>
            <Image src={IconArrowRight} pointer />
          </Div>
        </Div>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
          inline="between"
          col="12"
        >
          <Div className="d-flex align-self-start" col="12">
            <Label
              value="SAC"
              style={{
                ...styles.labelSAC,
                color: parametros.corPrimaria,
              }}
              family="Bold"
            />
            <Label
              marginLeft="2"
              value="Serviço de atendimento ao consumidor"
              style={{
                ...styles.labelServicoAtendimento,
                color: parametros.corPrimaria,
              }}
              family="Bold"
            />
          </Div>
          <Div className="d-flex flex-column align-items-start" col="12">
            <Label
              value={Mask.getValueMaskPhone(empresa.telefone)}
              style={styles.labelTelefone}
              family="Bold"
            />
            <Label
              value={parametros.emailAtendimento}
              style={{
                ...parametros.labelEmail,
                color: parametros.corPrimaria,
              }}
              family="Bold"
            />
          </Div>
        </Div>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
          inline="start"
        >
          <Div
            style={{
              color: "white",
              backgroundColor: parametros.corPrimaria,
              width: 120,
              height: 120,
              margin: 10,
              padding: 10,
              borderRadius: 10,
            }}
            inline="center"
          >
            <Div column>
              <Image src={IconMensagem} width="50px" />
              <Label value="Mensagem" marginTop="3" />
            </Div>
          </Div>
          <Div
            style={{
              color: "white",
              backgroundColor: "#25D366",
              width: 120,
              height: 120,
              margin: 10,
              padding: 10,
              borderRadius: 10,
            }}
            inline="center"
            pointer
            handlerClick={() => {
              window.open(linkWhatsApp, "_blank");
            }}
          >
            <Div column pointer>
              <Image src={IconWhatsapp} width="45px" pointer />
              <Label value="Whatsapp" marginTop="3" pointer />
            </Div>
          </Div>
        </Div>

        <Div
          style={{
            backgroundColor: parametros.corRodape,
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "2%",
            paddingBottom: "2%",
            marginTop: 300,
            width: "100%",
          }}
          inline="between"
        >
          <Div>
            <Label
              value="Baixe nosso app"
              style={{ color: Color.ECOMMERCE.WHITE, fontSize: 18 }}
              family="Bold"
            />
            <Div inline="start">
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: 140,
                  height: 45,
                  padding: 5,
                  color: "white",
                }}
                inline="center"
                pointer
              >
                <Image src={IconApple} width="20px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="App Store"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="iOS"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: 140,
                  height: 45,
                  padding: 5,
                  color: "white",
                  marginLeft: 15,
                }}
                inline="center"
                pointer
              >
                <Image src={IconGooglePlay} width="20px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="Google Play"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="Android"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
            </Div>
          </Div>
          <Div>
            <Label
              value="Redes sociais"
              style={{ color: Color.ECOMMERCE.WHITE, fontSize: 18 }}
              family="Bold"
            />
            <Div inline="start">
              <a href={parametros.urlFacebook} target="_blank">
                <Image
                  src={IconFacebook}
                  width="45px"
                  height="45px"
                  marginRight="1"
                  pointer
                />
              </a>
              <a href={parametros.urlInstagram} target="_blank">
                <Image
                  src={IconInstagram}
                  width="45px"
                  height="45px"
                  marginLeft="2"
                  pointer
                />
              </a>
            </Div>
          </Div>
        </Div>
      </Div>
    );
  };

  renderTemplateMenuCarrinho = () => {
    return (
      <Carrinho
        {...this.props}
        handlerAddItem={(produto) => this.props.handlerAddItem(produto)}
        handlerRemoveItem={(produto) => this.props.handlerRemoveItem(produto)}
        handlerFecharMenu={() => this.onSetSidebarOpen(false)}
      />
    );
  };

  getPropsSideBarMenuLeft = () => {
    return {
      sidebar: this.renderTemplateMenuLeft(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      styles: {
        sidebar: {
          background: "white",
          width: "35%",
          height: "100%",
          minHeight: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBarMenuUsuario = () => {
    return {
      sidebar: this.renderMenuUsuario(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: Utils.isViewMobile() ? "80%" : "25%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBarMenuCarrinho = () => {
    return {
      sidebar: this.renderTemplateMenuCarrinho(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: Utils.isViewMobile() ? "80%" : "25%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBar() {
    const { isSideBarMenuLeft, abrirMenuUsuario } = this.state;

    let propsSideBar = null;
    if (isSideBarMenuLeft) {
      propsSideBar = this.getPropsSideBarMenuLeft();
    } else {
      if (abrirMenuUsuario) {
        propsSideBar = this.getPropsSideBarMenuUsuario();
      } else {
        propsSideBar = this.getPropsSideBarMenuCarrinho();
      }
    }

    return propsSideBar;
  }

  getUsuarioLogado() {
    // let usuario = localStorage.getItem(Keys.KEY_USUARIO_LOJA_WEB);
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    return Utils.isValueValid(usuario) ? usuario : {};
  }

  getEnderecoPrincipal(usuario) {
    let endereco = {};

    if (Utils.isValueValid(usuario)) {
      endereco = Array.from(usuario.enderecos).filter(
        (endereco) => endereco.principal
      )[0];
    }

    return endereco;
  }

  getQuantidadeProdutos(carrinho) {
    let quantidade = "0";

    if (Utils.isValueValid(carrinho.cabecalho)) {
      quantidade = carrinho.itens.length || "0";
    }

    return Number(quantidade).toFixed(0);
  }

  getValorTotalPedido(carrinho) {
    let valorTotal = "0";

    if (Utils.isValueValid(carrinho.cabecalho)) {
      valorTotal = carrinho.cabecalho.valorTotal || "0";
    }

    return valorTotal;
  }

  fecharMenuBusca(idBtnFecharMenu) {
    let div = document.getElementById(this.props.idPesquisa);
    if (Utils.isValueValid(div) && div.classList.contains("show")) {
      div.classList.toggle("collapsed");
      document.getElementById(idBtnFecharMenu).click();
    }
  }

  realizarPesquisa(texto, idBtnFecharMenu) {
    Http.get(`/busca/${texto}`)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              resultadoPesquisa: data,
            },
            () => {
              if (data.sucessoPesquisa) {
                this.props.history.push("/resultadopesquisa", {
                  resultadoPesquisa: data,
                  textoPesquisa: texto,
                  menus: this.state.menus,
                  empresa: this.props.empresa,
                });
                this.fecharMenuBusca(idBtnFecharMenu);
              } else {
                toast.error(
                  `Os produtos não foram encontrados para o termo pesquisado: "${texto}"`
                );
              }

              this.setState({ textoPesquisa: "" });
            }
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  renderTemplateSobrePolitica() {
    return (
      <Div
        className="scrollhost"
        style={{
          background: "#f2f2f2",
          width: "60%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.TEMPLATE_POLITICA,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
          padding: 50,
        }}
      >
        <Div
          inline="end"
          handlerClick={() =>
            this.setState({
              abrirMapa: false,
              abrirPoliticaRegulamento: false,
              abrirSobreEmpresa: false,
            })
          }
          style={{ position: "fixed", top: 10, right: 10 }}
          pointer
        >
          <I
            icon={Type.ICON.CLOSEX}
            sizeIcon="2"
            colorText={Color.NODE.DANGER}
            pointer
          />
        </Div>
        {this.state.abrirPoliticaRegulamento ? (
          <div
            dangerouslySetInnerHTML={{ __html: this.state.politicaEmpresa }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: this.state.sobreEmpresa }} />
        )}
      </Div>
    );
  }

  getEnderecoSelecionado(endereco) {
    let selecionado = false;

    let carrinho = CarrinhoUtils.getCarrinho();

    if (Utils.isValueValid(carrinho)) {
      let enderecoEntrega = carrinho.enderecoEntrega;
      if (Utils.isValueValid(enderecoEntrega)) {
      }

      if (Utils.isValueValid(enderecoEntrega)) {
        selecionado =
          enderecoEntrega.idClienteEndereco === endereco.idClienteEndereco;
      }
    }

    return selecionado;
  }

  setEnderecoSelecionado(endereco) {
    let carrinho = {
      ...this.props.carrinho,
      enderecoEntrega: endereco,
    };

    this.props.setEnderecoEntrega(carrinho);
  }

  renderMeusLocaisDeEntrega(enderecos) {
    let empresa = this.props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};
    return (
      <Div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <Label
          className="modal-title"
          style={{
            color: parametros.corPrimaria,
            fontSize: 20,
            marginTop: 10,
          }}
          family="Bold"
          value="Meu local de entrega"
        />
        <Div marginTop="2" style={styles.containterLocaisEntrega}>
          {enderecos.map((endereco, index) => {
            let isEnderecoSelecionado = this.getEnderecoSelecionado(endereco);
            return (
              <Div inline="start" key={index}>
                <Div className="d-flex flex-column justify-content-start align-items-start"></Div>
                <RadioCustom
                  checked={isEnderecoSelecionado}
                  handlerChange={(e) => {
                    this.setEnderecoSelecionado(endereco);
                  }}
                  left
                  value={
                    <>
                      <Label
                        value={endereco.descricao}
                        style={{
                          lineHeight: "15px",
                          fontSize: 13,
                          width: 200,
                          color: Color.ECOMMERCE.CINZA_ESCURO,
                        }}
                        family="Bold"
                        className="text-ellipsis"
                        pointer
                      />
                      <Label
                        value={`${endereco.logradouro}, ${endereco.bairro}`}
                        style={{
                          lineHeight: "13px",
                          fontSize: 13,
                          width: 200,
                          color: Color.ECOMMERCE.CINZA,
                        }}
                        pointer
                        family="Light"
                        className="text-ellipsis"
                      />
                    </>
                  }
                />
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }

  renderMenuUsuario() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    let empresa = this.props.empresa || {};

    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    return (
      <Div className="scrollhost">
        <I
          icon={Type.ICON.CLOSEX}
          colorText={Color.NODE.DANGER}
          style={{ position: "absolute", right: 10, top: 10 }}
          sizeIcon="2"
          handlerClick={() => {
            this.setState({ abrirMenuUsuario: false }, () =>
              this.onSetSidebarOpen(false)
            );
          }}
        />
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
          paddingTop="4"
          inline="start"
          className="border-bottom"
        >
          <I
            icon={Type.ICON.USER}
            style={{
              fontSize: 50,
              color: parametros.corPrimaria,
              margin: 10,
            }}
          />
          <Label
            value={`Olá ${cliente.nome}`}
            family="SemiBold"
            style={{ marginTop: 10, color: Color.ECOMMERCE.CINZA_ESCURO }}
          />
        </Div>
        {this.renderMeusLocaisDeEntrega(cliente.enderecos)}
        <Div className="fixed-bottom">
          <Div
            style={{ paddingLeft: "10%", paddingRight: "10%" }}
            className="pt-2 pb-2 border-top border-bottom"
          >
            <Div
              inline="between"
              paddingLeft="2"
              style={{ paddingRight: "10%" }}
              pointer
              handlerClick={() => {
                this.onSetSidebarOpen(false);
                this.props.history.push(Routes.CLIENTE, {
                  corPrimaria: parametros.corPrimaria,
                  corSecundaria: parametros.corSecundaria,
                  urlFotoEmpresa: parametros.urlFoto,
                });
              }}
            >
              <Div inline="start">
                <I
                  icon={Type.ICON.USERR}
                  pointer
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  value="Dados pessoais"
                  pointer
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
          <Div
            style={{ paddingLeft: "10%", paddingRight: "10%" }}
            className="pt-2 pb-2 border-bottom"
          >
            <Div
              inline="between"
              paddingLeft="2"
              pointer
              style={{ paddingRight: "10%" }}
              handlerClick={() => {
                this.setState({ abrirMenuUsuario: false }, () => {
                  this.onSetSidebarOpen(false);
                  this.props.history.push(Routes.CLIENTE_PEDIDOS, {
                    corPrimaria: parametros.corPrimaria,
                    urlFotoEmpresa: parametros.urlFoto,
                  });
                });
              }}
            >
              <Div inline="start">
                <I
                  icon={Type.ICON.REORDER}
                  pointer
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  value="Meus pedidos"
                  pointer
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
          <Div
            style={{ paddingLeft: "10%", paddingRight: "10%" }}
            className="pt-2 pb-2 border-top border-bottom"
          >
            <Div
              inline="between"
              paddingLeft="2"
              style={{ paddingRight: "10%" }}
              pointer
              handlerClick={() => {
                this.onSetSidebarOpen(false);
                this.props.history.push(Routes.LISTAS_COMPRAS, {
                  corPrimaria: parametros.corPrimaria,
                  corSecundaria: parametros.corSecundaria,
                  urlFotoEmpresa: parametros.urlFoto,
                  parametrosPedido: this.props.parametros,
                  empresa: this.props.empresa,
                });
              }}
            >
              <Div inline="start">
                <I
                  icon={Type.ICON.LIST_ALT}
                  pointer
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  value="Listas de compra"
                  pointer
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
          <Div
            style={{ paddingLeft: "10%", paddingRight: "10%" }}
            className="pt-2 pb-2 border-bottom"
            pointer
          >
            <Div
              inline="between"
              paddingLeft="2"
              pointer
              style={{ paddingRight: "10%" }}
            >
              <Div inline="start" handlerClick={() => this.sair()}>
                <I
                  pointer
                  icon={Type.ICON.SIGNOUT}
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  pointer
                  value="Sair"
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }

  enviarMensagemWhatsapp() {
    const { contatoWhatsapp } = this.state;
    let options = `top=${window.innerHeight / 4},left=${
      window.innerWidth / 4
    },location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=0`;

    let empresa = this.props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    window.open(
      `https://api.whatsapp.com/send?phone=55${Mask.clearMask(
        parametros.telefoneWhatsapp
      )}&text=${contatoWhatsapp.mensagem}`,
      "self",
      options
    );

    Utils.refreshPage();
  }

  renderConversarWhatsapp() {
    const { contatoWhatsapp } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    ModalNotification.renderMessage({
      title: "Em caso de dúvidas, entre em contato conosco",
      body: (
        <Div col="8">
          <TextAreaWithState
            label="Mensagem"
            rows="5"
            value={contatoWhatsapp.mensagem}
            handlerChange={(mensagem) => {
              this.setState({
                contatoWhatsapp: {
                  ...contatoWhatsapp,
                  mensagem,
                },
              });
            }}
          />
        </Div>
      ),
      responsive: "6",
      handlerConfirm: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
        this.enviarMensagemWhatsapp();
        this.forceUpdate();
      },
      handlerCancel: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
      },
      labelButtonConfirm: "Enviar mensagem",
      styleButtonConfirm: {
        backgroundColor: corPrimaria,
      },
    });
  }

  getNomeUsuario(usuario) {
    let nome = "";

    if (Utils.isValueValid(usuario)) {
      nome = String(usuario.nome).split(" ")[0];
    }

    return nome;
  }

  sair() {
    UsuarioUtils.removerUsuarioLojaWeb();
    this.props.history.push("/");
    Utils.refreshPage();
  }

  render() {
    const { carrinho, empresa } = this.props;
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};
    let { urlGoogleMaps } = this.state.filialMapa;

    let usuarioLogado = this.getUsuarioLogado() || {};
    //let enderecoPrincipal = this.getEnderecoPrincipal(usuarioLogado);

    return (
      <Div style={{ height: "auto" }}>
        <HeaderBarEmpresa
          politicaEmpresa={this.state.politicaEmpresa}
          sobreEmpresa={this.state.sobreEmpresa}
          renderConversarWhatsapp={this.renderConversarWhatsapp}
          urlGoogleMaps={urlGoogleMaps}
          empresa={this.props.empresa}
        />
        <Div inline="start" className="w-100">
          <Image
            src={this.state.imagemLogo}
            style={{ zIndex: Zindex.DEFAULT }}
            className="imagemlogoinput"
            handlerClick={() => this.props.history.replace("/")}
            pointer
          />
          <Div className="ml-5 mr-2" style={{ height: 100 }}>
            <InputSearchLojaInstantanea
              idPesquisa={this.props.idPesquisa}
              id={ID_INPUT_SEARCH}
              handlerChangePesquisa={(texto, idBtnFecharMenu) => {
                this.realizarPesquisa(texto, idBtnFecharMenu);
              }}
              handlerFecharPesquisa={(idBtnFecharMenu) =>
                this.fecharMenuBusca(idBtnFecharMenu)
              }
              empresa={this.props.empresa}
              textoPesquisa={this.state.textoPesquisa}
              handlerAbrirPesquisa={this.props.handlerAbrirPesquisa}
            />
          </Div>
          <Div
            style={{
              zIndex: Zindex.BOTOES_CADASTRAR_REGISTRAR,
              display: Utils.isValueValid(usuarioLogado) ? "none" : "block",
              marginLeft: 15,
            }}
          >
            <Label
              value="Cadastre-se"
              style={styles.btnRegistrar}
              marginLeft="1"
              family="SemiBold"
              marginRight="1"
              pointer
              handlerClick={() =>
                this.props.history.push("/cadastrarcliente", {
                  logoEmpresa: parametros.urlFoto,
                  empresa: empresa,
                })
              }
            />
            <Label
              value="Entrar"
              style={{
                ...styles.btnEntrar,
                color: parametros.corPrimaria,
              }}
              marginLeft="1"
              marginRight="1"
              pointer
              family="SemiBold"
              handlerClick={() =>
                this.setState({ realizarLogin: true }, () =>
                  Utils.toggleScrollBody("hidden")
                )
              }
            />
          </Div>
          {/* <Div
            column
            style={{ width: 100, zIndex: Zindex.BOTOES_ME_AJUDA }}
            pointer
            handlerClick={() => this.renderConversarWhatsapp()}
          >
            <I
              icon={Type.ICON.QUESTION_CIRLE}
              style={{ color: parametros.corPrimaria, fontSize: 30 }}
              pointer
            />
            <Label value="Me ajuda" pointer family="Light" />
          </Div> */}
          <Div
            style={{
              zIndex: Zindex.DEFAULT,
            }}
            inline="end"
          >
            <If and value1={Utils.isValueValid(usuarioLogado)}>
              <Div
                className="d-flex flex-column container-nome-usuario"
                style={{ width: 100, marginTop: 16 }}
                marginRight="4"
                handlerClick={() => this.fecharMenuBusca(ID_INPUT_SEARCH)}
              >
                <Div
                  handlerClick={() => {
                    this.setState(
                      {
                        isSideBarMenuLeft: false,
                        abrirMenuUsuario: true,
                      },
                      () => {
                        this.onSetSidebarOpen(true);
                      }
                    );
                  }}
                  column
                  pointer
                >
                  <I
                    icon={Type.ICON.USER}
                    style={{
                      fontSize: 30,
                      color: parametros.corPrimaria,
                    }}
                    pointer
                  />
                  <Label
                    value={this.getNomeUsuario(usuarioLogado)}
                    family="Light"
                    pointer
                  />
                </Div>
              </Div>
            </If>
            <If and value1={this.state.realizarLogin}>
              <Div
                style={{
                  backgroundColor: "black",
                  opacity: 0.5,
                  zIndex: Zindex.BACKGROUND_MENU_CLIENTE,
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                  height: "100vh",
                }}
              />
              <Div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: 100,
                  transform: `translateX(-50%)`,
                  backgroundColor: "#F2F2F2",
                  borderRadius: 5,
                  padding: 10,
                  zIndex: Zindex.MENU_LOGIN,
                }}
              >
                <LoginClienteMenu
                  history={this.props.history}
                  empresa={this.props.empresa}
                  handlerCloseMenu={() =>
                    this.setState({ realizarLogin: false }, () =>
                      Utils.toggleScrollBody("auto")
                    )
                  }
                />
              </Div>
            </If>
            <If and value1={this.state.mostrarMenuUsuario}>
              <Div
                style={{
                  backgroundColor: "black",
                  opacity: 0.5,
                  zIndex: Zindex.BACKGROUND_MENU_CLIENTE,
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                  height: "100vh",
                }}
              />
              <Div
                style={{
                  position: "absolute",
                  top: 60,
                  transform: `translateX(-60px)`,
                  backgroundColor: "#F2F2F2",
                  borderRadius: 5,
                  padding: 10,
                  zIndex: Zindex.MENU_CLIENTE,
                }}
              >
                <MenuCliente
                  handlerCloseMenu={() =>
                    this.setState({ mostrarMenuUsuario: false })
                  }
                  parametros={this.state.parametros}
                  history={this.props.history}
                />
              </Div>
            </If>
            <Div
              className="d-flex flex-column align-items-end"
              style={{ width: 88 }}
              handlerClick={() => {
                this.setState(
                  {
                    isSideBarMenuLeft: false,
                    mostrarMenuUsuario: false,
                  },
                  () => {
                    this.onSetSidebarOpen(true);
                  }
                );
              }}
            >
              <Div>
                <I
                  icon={Type.ICON.SALE_BASKET}
                  style={{
                    ...styles.menuCarrinho,
                    background: parametros.corPrimaria,
                  }}
                  pointer
                />
              </Div>
              <Label
                value={this.getQuantidadeProdutos(carrinho || {})}
                style={styles.labelQuantidadeTotalItens}
                family="Bold"
                pointer
              />
              <Label
                value={Mask.formatValueBr(
                  this.getValorTotalPedido(carrinho || {})
                )}
                style={styles.labelValorTotalCarrinho}
                family="Bold"
                margin="2"
              />
            </Div>
          </Div>
        </Div>
        <Sidebar {...this.getPropsSideBar()} className="scrollhost"></Sidebar>
        <If
          and
          value1={this.state.abrirNossasLojas}
          value2={!this.state.abrirMapa}
        >
          {this.renderTemplateNossasLojas()}
        </If>
        <If
          and
          value1={this.state.abrirNossasLojas}
          value2={this.state.abrirMapa}
        >
          {this.renderMapaLoja()}
        </If>
        <If
          and
          value1={
            this.state.abrirSobreEmpresa || this.state.abrirPoliticaRegulamento
          }
        >
          {this.renderTemplateSobrePolitica()}
        </If>

        <Div
          handlerClick={() => {
            this.forceUpdate();
          }}
          id="forceUpdateGambiarra"
        />
      </Div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEnderecoEntrega }, dispatch);

const mapStateToProps = (store) => {
  let div = document.getElementById("forceUpdateGambiarra");
  if (Utils.isValueValid(div)) {
    setTimeout(() => {
      div.click();
    }, 100);
  }
  return {
    ...store.carrinhoState,
    ...store.parametrosPedidoState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavegacaoWeb);
