import React, { useEffect, useState } from "react";
import {
  Checkbutton,
  Div,
  I,
  If,
  InputDefault,
  Label,
  ModalNotification,
  RadioCustom,
  Row,
  Select,
  SelectValue,
  TextArea,
  Title,
} from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";
import UsuarioUtils from "../utils/UsuarioUtils";
import EntregaRetiradaUtils from "../utils/EntregaRetiradaUtils";
import ContatoWhatsappGenerico from "../whatsapp/ContatoWhatsappGenerico";
import Zindex from "../utils/Zindex";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textTransform: "uppercase",
    width: "100%",
    marginLeft: "5px",
  },
  boxInfo: {
    marginBottom: "0px",
    padding: "10px 0 0px 0",
    backgroundColor: "#FFF",
    alignItems: "end",
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },

  enderecoSelecionado: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "white",
  },
  enderecoNaoSelecionado: {
    backgroundColor: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "#878787",
  },
  selecioneHorario: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "#878787",
    borderRadius: 5,
    padding: 10,
    cursor: "pointer",
    width: "100%",
    lineHeight: 0.9,
  },
  labelAtencao: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 12,
  },
  containterHorario: {
    backgroundColor: "#F2F2F2",
    padding: 15,
    borderRadius: 10,
    margin: 10,
  },
  containterHorarioSelecionado: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    padding: 15,
    borderRadius: 10,
    margin: 10,
  },
  labelInfo: {
    color: "#575757",
    fontSize: 18,
  },
};

const EntregaRetirada = ({
  handlerEnderecoSelecionado,
  idClienteEnderecoSelecionado,
  adicionarEndereco,
  handlerFilialSelecionada,
  filialSelecionadaRetirada,
  parametros,
  handlerHorarioSelecionado,
}) => {
  const [tipoEntrega, setTipoEntrega] = useState(true);
  const [obs, setObs] = useState("");
  const [isObs, setIsObs] = useState(false);
  const [enderecos, setEnderecos] = useState([]);
  const [empresa, setEmpresa] = useState({ filiais: [] });
  const [idClienteEndereco, setIdClienteEndereco] = useState(
    idClienteEnderecoSelecionado
  );
  const [idClienteFilial, setIdClienteFilial] = useState(null);
  const [horarios, setHorarios] = useState([]);
  const [outroHorarioSelecionado, setOutroHorarioSelecionado] = useState({ idEmpresaHorarioEntrega: ''});
  const [diasPrevisaoEntrega, setDiasPrevisaoEntrega] = useState(0);

  useEffect(() => {
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    let enderecos = Array.from(usuario.enderecos);

    if (enderecos) {
      setEnderecos(enderecos);

      if (!idClienteEnderecoSelecionado) {
        enderecos.forEach((end) => {
          if (end.principal) {
            setIdClienteEndereco(end.idClienteEndereco);
            handlerEnderecoSelecionado(end);
          }
        });
      }
    }
    if (parametros) {
      setHorarios(parametros.horarios);
      setDiasPrevisaoEntrega(parametros.diasPrevisaoEntrega);
    }
    consultarEmpresa();
  }, []);

  useEffect(() => {
    if (parametros) {
      setHorarios(parametros.horarios);
      setDiasPrevisaoEntrega(parametros.diasPrevisaoEntrega);
    }
  }, [parametros]);

  function consultarEmpresa() {
    Http.get("/empresa")
      .then(({ status, data }) => {
        if (status) {
          setEmpresa(data);
          setFilialSelecionada(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  function setFilialSelecionada(empresa) {
    if (Utils.isValueValid(empresa)) {
      let filiais = Array.from(empresa.filiais);

      if (Utils.isValueValid(filiais)) {
        handlerFilialSelecionada(filiais[0]);
        setIdClienteFilial(filiais[0].idFilial);
      }

      if (Utils.isValueValid(filialSelecionadaRetirada)) {
        setIdClienteFilial(filialSelecionadaRetirada.idFilial);
      }
    }
  }

  function renderDiasPrevisaoEntrega() {
    let dataHoje = horarios.length > 0 ? horarios[0].dataEntrega : "";

    return (
      <Div inline="start" className="w-100" style={{ cursor: "pointer" }}>
        <Label
          value="PREVISÃO DE ENTREGA"
          style={styles.labelInfo}
          family="SemiBold"
        />
        <Div row style={styles.selecioneHorario}>
          <Div column>
            <Label
              style={{
                margin: 0,
                padding: "10px",
                color: Color.EMPRESA.SECUNDARIA,
              }}
              value={`Data: ${dataHoje}`}
              family="Bold"
            />
          </Div>
        </Div>
      </Div>
    );
  }

  function getHorariosEntrega() {
    let horariosEntrega = [];
    if (Utils.isValueValid(horarios)) {
      horariosEntrega = Array.from(horarios).filter(
        (h) => h.tipoEntrega === "ENTREGA"
      );
    }

    return horariosEntrega;
  }

  function getHorariosSelecaoBody(todosHorarios) {
    let horarioSelecionado = outroHorarioSelecionado;
    let horariosSplit = Utils.quebrarArray(todosHorarios, 3);

    return (
      <Div column>
        <Div
          className="d-flex flex-column justify-content-start"
          marginTop="3"
          style={{ color: "#25D366", width: "100%", padding: "0 4px" }}
        >
          <ContatoWhatsappGenerico
            empresa={empresa}
            zIndex={Zindex.MIL_E_DOIS}
            title="Entre em contato com um de nossos atendentes para antecipar um pedido"
            value={{ mensagem: "Olá, desejo antecipar minha entrega!" }}
            width="100%"
            height="47px"
            corPrimaria={Color.EMPRESA.PRIMARIA}
          />
        </Div>

        {horariosSplit.map((horarios, index) => {
          return (
            <Div inline="center">
              {Array.from(horarios).map((horario, key) => {
                let isSelected =
                  horarioSelecionado.descricao === horario.descricao &&
                  horarioSelecionado.dataEntrega === horario.dataEntrega;
                return (
                  <Div
                    column
                    style={{
                      color: isSelected ? "white" : Color.ECOMMERCE.BLACK_GRAY,
                      backgroundColor: isSelected
                        ? Color.EMPRESA.PRIMARIA
                        : Color.ECOMMERCE.CINZA,
                      padding: 21,
                      borderRadius: 5,
                      height: 90,
                      opacity: 1,
                      margin: 5,
                      justifyContent: "center",
                      fontSize: 14,
                      lineHeight: 0.8,
                    }}
                    pointer
                    handlerClick={() => {
                      setOutroHorarioSelecionado(horario);
                      renderSelecionarOutroHorario(todosHorarios);
                    }}
                  >
                    <Label
                      value={`${horario.dataEntrega}`}
                      family="Bold"
                      pointer
                    />
                    <Label
                      value={`${horario.descricao}`}
                      family="Bold"
                      pointer
                    />
                  </Div>
                );
              })}
            </Div>
          );
        })}
      </Div>
    );
  }

  function renderSelecionarOutroHorario(horarios) {
    ModalNotification.renderMessage({
      title: "Selecione outro horário",
      styleButtonConfirm: {
        border: "none",
        backgroundColor: Color.EMPRESA.PRIMARIA,
      },
      styleTitle: {
        color: "#FFF",
      },
      styleHeader: {
        border: "none",
        backgroundColor: Color.EMPRESA.PRIMARIA,
      },
      body: getHorariosSelecaoBody(horarios),
      handlerConfirm: () => {
        let outroHorario = outroHorarioSelecionado;
        Array.from(horarios).map((horario) => (horario.active = false));

        let horarioSelecionado = horarios.filter(
          (h) =>
            h.descricao === outroHorario.descricao &&
            h.dataEntrega === outroHorario.dataEntrega
        )[0];
        if (Utils.isValueValid(horarioSelecionado)) {
          horarioSelecionado.active = !horarioSelecionado.active;
        }

        handlerHorarioSelecionado(horarioSelecionado);
      },
      handlerCancel: () => {},
    });
  }

  function renderHorariosEntregaNovo() {
    let todosHorarios = getHorariosEntrega();

    let horarioSelecionado =
      todosHorarios.filter((h) => h.active)[0] || todosHorarios[0];

    if (todosHorarios.length === 0) {
      horarioSelecionado = {
        dataEntrega: "",
        descricao: "",
      };
    }

    return (
      <Div inline="start" className="w-100" style={{ cursor: "pointer" }}>
        <Label
          value="AGENDE SEU HORÁRIO"
          style={styles.labelInfo}
          family="SemiBold"
        />
        <Div
          row
          style={styles.selecioneHorario}
          handlerClick={() => renderSelecionarOutroHorario(todosHorarios)}
        >
          <Div column>
            <Label value="Selecione seu Horário" family="Bold" />
            <Label
              value={`${horarioSelecionado.dataEntrega} ${horarioSelecionado.descricao}`}
              family="Light"
              style={{ fontSize: 22 }}
            />
          </Div>
          <I
            pointer
            icon={Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 20,
              display: "flex",
              alignItems: "center",
              marginTop: 7,
              marginLeft: 10,
            }}
          />
        </Div>
      </Div>
    );
  }

  //MOBILE

  function getHorariosEntregaSelect() {
    let horariosEntrega = [];

    if (Utils.isValueValid(horarios)) {
      horariosEntrega = Array.from(horarios).filter(
        (h) => h.tipoEntrega === "ENTREGA"
      );
    }

    let horariosSelect = [new SelectValue("", "Selecione um horário")];

    horariosEntrega.forEach((horario) => {
      horariosSelect.push(
        new SelectValue(
          horario.idEmpresaHorarioEntrega,
          `${horario.dataEntrega} ${horario.descricao}`
        )
      );
    });

    return horariosSelect;
  }

  function renderHorariosEntregaMobile() {
    let todosHorarios = getHorariosEntrega();

    return (
      <Div inline="center" style={{ ...styles.item }}>
        <Select
          responsive="12"
          style={{
            border: "none",
            borderBottom: `1px solid #f2f2f2`,
            textAlignLast: "center",
            marginBottom: 0,
          }}
          valueSelected={outroHorarioSelecionado.idEmpresaHorarioEntrega}
          handlerChange={(e) => {
            let idEmpresaHorarioEntregaSelecionado = e.target.value;
            Array.from(todosHorarios).map(
              (horario) => (horario.active = false)
            );

            let horarioSelecionado = todosHorarios.filter(
              (h) =>
                h.idEmpresaHorarioEntrega === idEmpresaHorarioEntregaSelecionado
            )[0];
            if (Utils.isValueValid(horarioSelecionado)) {
              horarioSelecionado.active = !horarioSelecionado.active;
            }        
            //setHorarios(horarios)
            setOutroHorarioSelecionado({
              idEmpresaHorarioEntrega: '',
              ...horarioSelecionado
            });
            handlerHorarioSelecionado(horarioSelecionado);
          }}
          values={getHorariosEntregaSelect()}
        />
      </Div>
    );
  }

  function renderDiasPrevisaoEntregaMobile() {
    let dataHoje = horarios.length > 0 ? horarios[0].dataEntrega : "";

    return (
      <Div inline="start" className="w-100" style={{ cursor: "pointer" }}>
        <Label
          value="PREVISÃO DE ENTREGA"
          style={styles.labelInfo}
          family="SemiBold"
        />
        <Div row style={styles.selecioneHorario}>
          <Div column>
            <Label
              style={{
                margin: 0,
                padding: "10px",
                color: Color.EMPRESA.SECUNDARIA,
              }}
              value={`Data: ${dataHoje}`}
              family="Bold"
            />
          </Div>
        </Div>
      </Div>
    );
  }

  return (
    <>
      <Row
        col="12"
        style={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          fontSize: "18px",
          padding: "5px 20px",
          border: "1px solid #dee2e6",
          borderBottom: "none",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        family="Bold"
      >
        <Div>
          <Label
            value="Opções de Entrega/Retirada"
            style={styles.labelResumoCompra}
            family="SemiBold"
            marginBottom="0"
          />
        </Div>
      </Row>
      <Div
        col="12"
        className="border"
        style={{ padding: "10px 20px", marginBottom: "30px" }}
      >
        <Row col="12" style={{ alignItems: "center", justifyContent: "start" }}>
          <Checkbutton
            corPrimaria={Color.EMPRESA.PRIMARIA}
            checked={tipoEntrega}
            title="Receber o pedido"
            onClick={() => {
              setTipoEntrega(!tipoEntrega);
              adicionarEndereco(EntregaRetiradaUtils.TIPO_ENTREGA);
            }}
            style={{
              margin: "9px 15px 9px 0",
              width: "196px",
              height: "55px",
              fontSize: "15px",
              flexDirection: "row",
              justifyContent: "start",
              border: "1px solid #dee2e6",
            }}
            Icon={
              <I
                pointer
                icon={Type.ICON.HOME}
                style={{
                  color: tipoEntrega
                    ? Color.ECOMMERCE.WHITE
                    : Color.EMPRESA.PRIMARIA,
                  fontSize: 23,
                  marginRight: "10px",
                }}
              />
            }
          />

          <Checkbutton
            corPrimaria={Color.EMPRESA.PRIMARIA}
            checked={!tipoEntrega}
            title="Retirar na loja"
            onClick={() => {
              setTipoEntrega(!tipoEntrega);
              adicionarEndereco(EntregaRetiradaUtils.TIPO_RETIRADA);
            }}
            style={{
              margin: "10px 15px 10px 0",
              width: "196px",
              height: "55px",
              fontSize: "15px",
              flexDirection: "row",
              justifyContent: "start",
              border: "1px solid #dee2e6",
            }}
            Icon={
              <I
                pointer
                icon={Type.ICON.MAP_MARKER}
                style={{
                  color: !tipoEntrega
                    ? Color.ECOMMERCE.WHITE
                    : Color.EMPRESA.PRIMARIA,
                  fontSize: 23,
                  marginRight: "10px",
                }}
              />
            }
          />
        </Row>

        <If and value1={!tipoEntrega}>
          <Row col="12" style={styles.boxInfo}>
            <Row col="12">
              <Title
                value="Selecione uma das Lojas"
                type="h6"
                className="label-valor-total-carrinho"
                style={{ ...styles.labelCinza, marginBottom: "24px" }}
                family="Bold"
              />
            </Row>
            <Div col="12" marginTop="2">
              {Array.from(empresa.filiais).map((filial, index) => {
                let idFilial = idClienteFilial;

                return (
                  <RadioCustom
                    checked={idFilial === filial.idFilial}
                    handlerChange={(e) => {
                      setIdClienteFilial(filial.idFilial);
                    }}
                    left
                    value={
                      <>
                        <Label
                          value={filial.fantasia}
                          style={{
                            lineHeight: "15px",
                            fontSize: 13,
                            width: "100%",
                            color: Color.ECOMMERCE.CINZA_ESCURO,
                          }}
                          family="Bold"
                          className="text-ellipsis"
                          pointer
                        />
                        <Label
                          value={`${filial.logradouro}, ${filial.bairro}`}
                          style={{
                            lineHeight: "13px",
                            fontSize: 13,
                            width: "100%",
                            color: Color.ECOMMERCE.CINZA,
                          }}
                          pointer
                          family="Light"
                          className="text-ellipsis"
                        />
                      </>
                    }
                  />
                );
              })}
            </Div>
          </Row>
        </If>

        <If and value1={tipoEntrega}>
          <Row col="12" style={styles.boxInfo}>
            <Row col="12">
              <Title
                value="Local de Entrega"
                type="h6"
                className="label-valor-total-carrinho"
                style={{ ...styles.labelCinza, marginBottom: "24px" }}
                family="Bold"
              />
            </Row>

            <Div col="12" marginTop="2">
              {enderecos.map((end, index) => {
                let idEndereco = idClienteEndereco;

                return (
                  <RadioCustom
                    checked={idEndereco === end.idClienteEndereco}
                    handlerChange={() => {
                      handlerEnderecoSelecionado(end);
                      setIdClienteEndereco(end.idClienteEndereco);
                    }}
                    left
                    value={
                      <>
                        <Label
                          value={end.descricao}
                          style={{
                            lineHeight: "15px",
                            fontSize: 13,
                            width: "100%",
                            color: Color.ECOMMERCE.CINZA_ESCURO,
                          }}
                          family="Bold"
                          className="text-ellipsis"
                          pointer
                        />
                        <Label
                          value={`${end.logradouro}, ${end.bairro}`}
                          style={{
                            lineHeight: "13px",
                            fontSize: 13,
                            width: "100%",
                            color: Color.ECOMMERCE.CINZA,
                          }}
                          pointer
                          family="Light"
                          className="text-ellipsis"
                        />
                      </>
                    }
                  />
                );
              })}
            </Div>
            <Row
              col="12"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                margin: "0px 19px 25px",
              }}
              padding="0"
              pointer
              onClick={() => {
                adicionarEndereco(EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO);
              }}
            >
              <I
                pointer
                icon={Type.ICON.PLUS_CIRCLE}
                style={{
                  color: Color.EMPRESA.PRIMARIA,
                  fontSize: 20,
                  marginRight: "10px",
                }}
              />
              <Title
                value="Adicionar novo endereço"
                type="h6"
                className="label-valor-total-carrinho"
                style={{
                  ...styles.labelCinza,
                  marginBottom: "5px",
                  marginTop: "-15px",

                  color: Color.EMPRESA.PRIMARIA,
                  fontSize: 14,
                }}
                family="Bold"
              />
            </Row>
          </Row>
        </If>

        <Div
          padding="3"
          style={{
            backgroundColor: "rgb(231, 232, 234)",
            borderRadius: "8px",
          }}
        >
          {/* <If and value1={diasPrevisaoEntrega === 0}>
            {renderHorariosEntregaNovo()}
          </If>
          <If and value1={diasPrevisaoEntrega > 0}>
            {renderDiasPrevisaoEntrega()}
          </If> */}

          <If and value1={diasPrevisaoEntrega === 0}>
            <Label
              value="AGENDE SEU HORÁRIO"
              style={styles.labelInfo}
              col="12"
              family="SemiBold"
            />

            {renderHorariosEntregaMobile()}
          </If>

          <If and value1={diasPrevisaoEntrega > 0}>
            {renderDiasPrevisaoEntregaMobile()}
          </If>
        </Div>

        <Row col="12" style={styles.boxInfo}>
          <Row
            col="12"
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginLeft: "5px",
            }}
            pointer
            onClick={() => setIsObs(!isObs)}
          >
            <I
              pointer
              icon={!isObs ? Type.ICON.PLUS_CIRCLE : Type.ICON.MINUS_SQUARE}
              style={{
                color: Color.EMPRESA.PRIMARIA,
                fontSize: 23,
                marginRight: "10px",
              }}
            />
            <Title
              value="Observações (opcional)"
              type="h6"
              className="label-valor-total-carrinho"
              style={{
                ...styles.labelCinza,
                marginBottom: "5px",
                marginTop: "-15px",
                color: Color.EMPRESA.PRIMARIA,
                fontSize: 14,
              }}
              family="Bold"
            />
          </Row>
          <If and value1={isObs}>
            <Div col="12" padding="0">
              <InputDefault
                placeHolder="Preencha caso tenha alguma observação no pedido"
                responsive="12"
                handlerChange={(e) => {
                  setObs(e.target.value);
                }}
                value={obs}
              />
            </Div>
          </If>
        </Row>
      </Div>
    </>
  );
};

export default EntregaRetirada;
