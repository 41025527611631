import React, { Component } from "react";
import { Color, Mask, Utils } from "../../utilities";
import ItemOfertaPrioridade from "../../views/anuncios/ItemOfertaPrioridade";
import ItemOfertaPrioridadeMobile from "../../views/anuncios/ItemOfertaPrioridadeMobile";
import { Label, Row } from "../../components"
import If from "../if/If";
import Div from "../layout/div/Div";
import Span from "../texts/span/Span";

import "./carousel.css";

class CarouselAnuncioOfertaVerticalMicro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      next: false,
      previos: true,
      empresa: props.empresa,
    };
  }

  visualizarProdutosAnuncio(anuncio) {
    if(!anuncio.estatico) {
      this.props.history.push("/anuncio/produtos", {
        ...anuncio,
        empresa: this.state.empresa,
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ empresa }) {
    this.setState({ empresa });
  }

  getWidthOferta() {
    let width = 270;

    if (Utils.isViewMobile()) width = 150;
    if (Utils.isViewMobileLarguraPequeno()) width = 125;

    return width;
  }

  labelsBanners(anuncio) {
    let titulo = ""
    let preco = ""

    if(Utils.isNotObjectEmpty(anuncio)) {
      titulo = anuncio.titulo
      preco = anuncio.preco
    }

    return (
      <>
      <Div
        style={{
          position: "absolute",
          color: "#FFF",
          width: "49%",
          height: "99%",
          borderRadius: "11px",
          zIndex: 996,
          padding: "20px 32px",
          fontWeight: "bolder",
          background: "#000",
          opacity: "0.4",
          display: "none"
        }}
      ></Div>
      <Div
        style={{
          position: "absolute",
          color: "#FFF",
          width: "100%",
          height: "100%",
          borderRadius: "11px",
          zIndex: 997,
          padding: "20px 32px",
          fontWeight: "bolder",
        }}
      >
        <If and value1={anuncio.exibirTitulo}>
          <Row>
            <Label style={{ fontSize: "26px" }} value={titulo} />
          </Row>

          <Label 
            style={{ 
              fontSize: "26px", 
              color: Color.EMPRESA.PRIMARIA 
            }} 
            value={  preco  ? preco : ""} 
          />
        </If>

      </Div>
      </>
    )
  }

  renderItemPrioridadeNormal(pagina, anuncios) {
    let widthOferta = this.getWidthOferta();
    return (
      <Div inline="start">
        {Utils.isValueValid(pagina[0]) && pagina[0].vertical && (
          <>
            {/* {this.labelsBanners(pagina[0])} */}
            <ItemOfertaPrioridade
              pagina={pagina[0]}
              labelsBanners={this.labelsBanners}
              widthImage={widthOferta}
              heightImage={450}
              handlerClick={() => this.visualizarProdutosAnuncio(pagina[0])}
              image={Utils.isValueValid(anuncios) ? pagina[0].image : null}
              isOfertaUm
              title={Utils.isValueValid(anuncios) ? pagina[0].titulo : ""}
              subTitle={Utils.isValueValid(anuncios) ? pagina[0].preco : ""}
              label={Utils.isValueValid(anuncios) ? pagina[0].precoOferta : ""}
            />
          </>
        )}

        {Utils.isValueValid(pagina[1]) && pagina[1].vertical && (
          
          <>
          {/* {this.labelsBanners(pagina[1], "283px")} */}
            <ItemOfertaPrioridade
              widthImage={widthOferta}
              pagina={pagina[1]}
              heightImage={450}
              handlerClick={() => this.visualizarProdutosAnuncio(pagina[1])}
              image={Utils.isValueValid(anuncios) ? pagina[1].image : null}
              isOfertaUm
              title={Utils.isValueValid(anuncios) ? pagina[1].titulo : ""}
              subTitle={Utils.isValueValid(anuncios) ? pagina[1].preco : ""}
              label={Utils.isValueValid(anuncios) ? pagina[1].precoOferta : ""}
              labelsBanners={this.labelsBanners}
            />
          </>
        )}

        <Div column>
          {Utils.isValueValid(pagina[1]) && !pagina[1].vertical && (
            <>
              
              <ItemOfertaPrioridade
                pagina={pagina[1]}
                widthImage={widthOferta}
                heightImage={220}
                handlerClick={() => this.visualizarProdutosAnuncio(pagina[1])}
                image={Utils.isValueValid(anuncios) ? pagina[1].image : null}
                isOfertaDois
                title={Utils.isValueValid(anuncios) ? pagina[1].titulo : ""}
                subTitle={Utils.isValueValid(anuncios) ? pagina[1].preco : ""}
                label={Utils.isValueValid(anuncios) ? pagina[1].precoOferta : ""}
                labelsBanners={this.labelsBanners}
              />
            </>
          )}
          {Utils.isValueValid(pagina[2]) && !pagina[2].vertical && (
            <ItemOfertaPrioridade
              pagina={pagina[2]}
              widthImage={widthOferta}
              heightImage={220}
              handlerClick={() => this.visualizarProdutosAnuncio(pagina[2])}
              image={Utils.isValueValid(anuncios) ? pagina[2].image : null}
              isOfertaDois
              title={Utils.isValueValid(anuncios) ? pagina[2].titulo : ""}
              subTitle={Utils.isValueValid(anuncios) ? pagina[2].preco : ""}
              label={Utils.isValueValid(anuncios) ? pagina[2].precoOferta : ""}
              labelsBanners={this.labelsBanners}
            />
          )}
        </Div>
      </Div>
    );
  }

  renderItemPrioridadeMobile(pagina, anuncios) {
    let widthOferta = this.getWidthOferta();
    return (
      <>
        <If and value1={Utils.isValueValid(pagina[0])}>
          <Div column style={{ width: "45%" }}>
            <ItemOfertaPrioridadeMobile
              pagina={pagina[0]}
              labelsBanners={this.labelsBanners}
              widthImage={widthOferta}
              heightImage={Utils.isViewMobile() ? 260 : null}
              handlerClick={() => this.visualizarProdutosAnuncio(pagina[0])}
              image={Utils.isValueValid(anuncios) ? pagina[0].image : null}
              isOfertaUm
              title={Utils.isValueValid(anuncios) ? pagina[0].titulo : ""}
              subTitle={Utils.isValueValid(anuncios) ? pagina[0].preco : ""}
              label={Utils.isValueValid(anuncios) ? pagina[0].precoOferta : ""}
            />
          </Div>
        </If>

        <Div column style={{ width: "45%" }}>
          <If and value1={Utils.isValueValid(pagina[1])}>
            <ItemOfertaPrioridadeMobile
              pagina={pagina[1]}
              labelsBanners={this.labelsBanners}
              widthImage={widthOferta}
              heightImage={Utils.isViewMobile() ? 125 : null}
              handlerClick={() => this.visualizarProdutosAnuncio(pagina[1])}
              image={Utils.isValueValid(anuncios) ? pagina[1].image : null}
              title={Utils.isValueValid(anuncios) ? pagina[1].titulo : ""}
              subTitle={Utils.isValueValid(anuncios) ? pagina[1].preco : ""}
              label={Utils.isValueValid(anuncios) ? pagina[1].precoOferta : ""}
            />
          </If>
          {Utils.isValueValid(pagina[2]) && (
            <ItemOfertaPrioridadeMobile
              pagina={pagina[2]}
              labelsBanners={this.labelsBanners}
              widthImage={widthOferta}
              heightImage={Utils.isViewMobile() ? 125 : null}
              handlerClick={() => this.visualizarProdutosAnuncio(pagina[2])}
              image={Utils.isValueValid(anuncios) ? pagina[2].image : null}
              title={Utils.isValueValid(anuncios) ? pagina[2].titulo : ""}
              subTitle={Utils.isValueValid(anuncios) ? pagina[2].preco : ""}
              label={Utils.isValueValid(anuncios) ? pagina[2].precoOferta : ""}
            />
          )}
        </Div>
      </>
    );
  }

  getClassIconPrev = () => {
    const { previos } = this.state;

    let classe = "d-flex align-items-center justify-content-start m-1 ";

    classe = classe.concat(
      Utils.isViewMobile()
        ? "icon-carousel-mobile "
        : "icon-prev-carousel-vertical-micro "
    );

    classe = classe.concat(previos ? "active" : "");

    return classe;
  };

  getClassIconNext = () => {
    const { next } = this.state;

    let classe = "d-flex align-items-center justify-content-end m-1 ";

    classe = classe.concat(
      Utils.isViewMobile()
        ? "icon-carousel-mobile "
        : "icon-prev-carousel-vertical-micro "
    );
    classe = classe.concat(next ? "active" : "");

    return classe;
  };

  getAnuncios() {
    let anuncios = Array.from(this.props.itens || []);
    let itens = [];

    if (Utils.isValueValid(anuncios)) {
      let verticais = anuncios.filter((a) => a.tipo === "VERTICAL") || [];
      let micros = anuncios.filter((a) => a.tipo === "MICRO") || [];

      if (Utils.isValueValid(micros)) {
        micros = Utils.quebrarArray(micros, 2);
      }

      let index = 0;

      verticais.forEach((vert, indexVertical) => {
        vert.vertical = true;

        let item = [];

        item.push(vert);

        if (!Utils.isValueValid(micros[index])) {
          let proximoVertical = verticais[indexVertical + 1];
          if (Utils.isValueValid(proximoVertical)) {
            item = [...item, proximoVertical];
          } else {
            item = [];
          }
        }

        let microsPagina = micros[index++];

        if (Utils.isValueValid(microsPagina)) {
          if (Utils.isValueValid(microsPagina[0]))
            item = [...item, { vertical: false, ...microsPagina[0] }];
          if (Utils.isValueValid(microsPagina[1]))
            item = [...item, { vertical: false, ...microsPagina[1] }];
        }

        if (Utils.isValueValid(item)) {
          itens = [...itens, item];
        }
      });
    }

    return itens;
  }

  render() {
    const ID_CAROUSEL = Utils.uuidString();

    let anuncios = this.getAnuncios();

    return (
      <Div style={{ width: 555 }}>
        <Div className="carousel slide" id={ID_CAROUSEL} dataRide="carousel">
          <Div className="carousel-inner">
            {Array.from(anuncios).map((pagina, index) => {
              return (
                <Div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={index}
                >
                  <If and value1={!Utils.isViewMobile()}>
                    {this.renderItemPrioridadeNormal(pagina, anuncios)}
                  </If>
                </Div>
              );
            })}
          </Div>
          <Div inline="between">
            <a
              href={`#${ID_CAROUSEL}`}
              role="button"
              data-slide="prev"
              onClick={() => this.setState({ previos: true, next: false })}
              className={this.getClassIconPrev()}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              href={`#${ID_CAROUSEL}`}
              role="button"
              data-slide="next"
              onClick={() => this.setState({ previos: false, next: true })}
              className={this.getClassIconNext()}
            >
              <Span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></Span>
            </a>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default CarouselAnuncioOfertaVerticalMicro;
