import axios from "axios";
import Loading from "../../components/spinner/Loading";
import Utils from "../utils/Utils";

const URL_TOKEN = "oauth/token";

//const BASE_URL = "https://pachecao.mobioh.com.br:443/loja/v1/"
//const BASE_URL_SOCKET = "https://pachecao.mobioh.com.br:443/loja/v1/"

//const BASE_URL = "https://www.stockei.mobioh.com.br:443/loja/v1/";
//const BASE_URL_SOCKET = "https://www.stockei.mobioh.com.br:443/loja/v1/";

//const BASE_URL = "https://www.maiitre.com.br:8586/loja/v1/";
//const BASE_URL_SOCKET = "https://www.maiitre.com.br:8586/loja/v1/";

//const BASE_URL = "https://primaveracasa.com.br/loja/v1/";
//const BASE_URL_SOCKET = "https://primaveracasa.com.br/loja/v1/";

//const BASE_URL = "https://hv.mobioh.com.br/loja/v1/";
//const BASE_URL_SOCKET = "https://hv.mobioh.com.br/loja/v1/";

const BASE_URL = `https://${window.location.hostname}:443/loja/v1/`;
const BASE_URL_SOCKET = `https://${window.location.hostname}:443/loja/v1/`;

//const BASE_URL = 'http://localhost:8586/loja/v1/'
//const BASE_URL_SOCKET = 'http://localhost:8586/loja/v1/'



const getAxiosCreate = async () => {
  let basicAuthorization = "Basic " + btoa("loja:loja");

  let CONFIG = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: basicAuthorization,
    },
  };

  return axios.create(CONFIG);
};

const getAxiosInstance = async () => {
  let axiosCreate = await getAxiosCreate();

  axiosCreate.interceptors.request.use(
    (config) => {
      Loading.show();
      return config;
    },
    (error) => {
      Loading.hide();
      Promise.reject(error);
    }
  );
  axiosCreate.interceptors.response.use(
    (response) => {
      Loading.hideTime();
      return response;
    },
    (error) => {
      let response = error.response;
      if (Utils.isValueValid(response)) {
        if (response.status !== Number(401)) {
          Loading.hide();
          return Promise.reject(error);
        }
      } else {
        Loading.hide();
        return Promise.reject(error);
      }

      Utils.refreshPage();
    }
  );
  return axiosCreate;
};

export const URL_TOKEN_SOCKET = () => {
  let basicAuthorization = btoa("loja:loja");

  return `${BASE_URL_SOCKET}ws?Basic=${basicAuthorization}`;
};

export const URL_TOKEN_SOCKET_NOVO = () => {
  let basicAuthorization = btoa("Basic loja:loja");

  return `${BASE_URL_SOCKET}ws`;
};

export const HEADER_SOCKET = () => {
  let basicAuthorization = "Basic " + btoa("Basic loja:loja");

  return {
    Authorization: basicAuthorization
  };
};

export default getAxiosInstance();
