import React, { Component } from "react";
import { Div, Image, ModalNotification } from "../components";
import TextAreaWithState from "../components/forms/textarea/TextAreaWithState";
import IconeWhatsapp from "../assets/images/icones/icon-whatsapp.png";
import { Mask, Utils } from "../utilities";

const initContatoWhatsapp = {
  telefone: "",
  mensagem: "Oi, vim do site e gostaria de saber mais sobre os produtos!",
};

export default class ContatoWhatsappMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contatoWhatsapp: {
        telefone: "",
        mensagem: "Oi, vim do site e gostaria de saber mais sobre os produtos!",
      },
    };
  }

  enviarMensagemWhatsapp() {
    const { contatoWhatsapp } = this.state;
    let options = `top=${window.innerHeight / 4},left=${
      window.innerWidth / 4
    },location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=0`;

    let empresa = this.props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    window.open(
      `https://api.whatsapp.com/send?phone=55${Mask.clearMask(
        parametros.telefoneWhatsapp
      )}&text=${contatoWhatsapp.mensagem}`,
      "self",
      options
    );

    Utils.refreshPage();
  }

  renderConversarWhatsapp() {
    const { contatoWhatsapp } = this.state;
    ModalNotification.renderMessage({
      title: "Entre em contato conosco",
      styleTitle: { fontSize: 15 },
      styleButtonCancel: { fontSize: 15 },
      styleButtonConfirm: { fontSize: 15 },
      body: (
        <Div col="12">
          <TextAreaWithState
            label="Mensagem"
            rows="5"
            value={contatoWhatsapp.mensagem}
            handlerChange={(mensagem) => {
              this.setState({
                contatoWhatsapp: {
                  ...contatoWhatsapp,
                  mensagem,
                },
              });
            }}
          />
        </Div>
      ),
      responsive: "6",
      handlerConfirm: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
        this.enviarMensagemWhatsapp();
        this.forceUpdate();
      },
      handlerCancel: () => {
        this.setState({ contatoWhatsapp: initContatoWhatsapp });
      },
      labelButtonConfirm: "Enviar",
    });
  }

  render() {
    return (
      <Image
        src={IconeWhatsapp}
        width="35px"
        style={
          this.props.style || {
            position: "fixed",
            bottom: 5,
            right: 5,
            backgroundColor: "#25D366",
            padding: 10,
            borderRadius: 50,
            zIndex: this.props.zIndex || 1001,
          }
        }
        handlerClick={() => this.renderConversarWhatsapp()}
        pointer
      />
    );
  }
}
