import React, {Component} from "react";
import {Div, I, If, Label, ModalNotification, Select, SelectValue, TextArea,} from "../../components";
import {Color, Http, Type, Utils} from "../../utilities";
import Zindex from "../utils/Zindex";
import ContatoWhatsappGenerico from "../whatsapp/ContatoWhatsappGenerico";

const styles = {
  filialSelecionada: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
  },
  selecioneHorario: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "#878787",
    borderRadius: 5,
    padding: 10,
    cursor: "pointer",
    width: "100%",
    lineHeight: 0.9,
  },
  filialNaoSelecionada: {
    backgroundColor: "#FFF",
    color: "#878787",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
  },
  labelInfo: {
    color: "#878787",
    fontSize: 18,
    marginTop: 15
  },

  containterHorario: {
    backgroundColor: "#F2F2F2",
    padding: 15,
    borderRadius: 10,
    margin: 10,
  },
  containterHorarioSelecionado: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    padding: 15,
    borderRadius: 10,
    margin: 10,
  },
  enderecoSelecionado: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "white",
  },
  enderecoNaoSelecionado: {
    backgroundColor: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "#878787",
  },
};

class TemplatePedidoRetirada extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresa: {},
      erroContinarPagamento: "",
      horarios: props.parametros.horarios || [],
      outroHorarioSelecionado: {},
    };
  }

  componentDidUpdate({parametros}) {
    if (!Utils.isValueValid(this.state.horarios)) {
      this.setState({horarios: parametros.horarios});
    }
  }

  componentDidMount() {
    this.consultarEmpresa();
  }

  consultarEmpresa() {
    Http.get("/empresa")
        .then(({status, data}) => {
          if (status) {
            this.setState({empresa: data}, () => this.setFilialSelecionada());
          }
        })
        .catch((erro) => console.log(erro));
  }

  setFilialSelecionada() {
    let empresa = this.state.empresa;
    if (Utils.isValueValid(empresa)) {
      let filiais = Array.from(empresa.filiais);

      if (Utils.isValueValid(filiais)) {
        this.props.handlerFilialSelecionada(filiais[0]);
      }
    }
  }

  getHorariosRetirada() {
    let horarios = this.state.horarios;

    let horariosRetirada = Array.from(horarios).filter(
        (h) => h.tipoEntrega === "RETIRADA"
    );

    return horariosRetirada;
  }

  getHorariosRetiradaSelect() {
    let horarios = this.state.horarios;

    let horariosEntrega = Array.from(horarios).filter(
        (h) => h.tipoEntrega === "RETIRADA"
    );

    let horariosSelect = [
      new SelectValue("", "Selecione um horário"),
    ];

    horariosEntrega.forEach((horario) => {
      horariosSelect.push(
          new SelectValue(
              horario.idEmpresaHorarioEntrega,
              `${horario.dataEntrega} ${horario.descricao}`
          )
      );
    });

    return horariosSelect;
  }

  getHorariosSelecaoBody(todosHorarios) {
    let horarioSelecionado = this.state.outroHorarioSelecionado;
    let horariosSplit = Utils.quebrarArray(todosHorarios, 3);

    return (
        <Div column>
          <Div
              className="d-flex flex-column justify-content-start"
              marginTop="3"
              style={{color: "#25D366", width: "100%", padding: "0 4px"}}
          >
            <ContatoWhatsappGenerico
                empresa={this.props.empresa}
                zIndex={Zindex.MIL_E_DOIS}
                title="Entre em contato com um de nossos atendentes para antecipar um pedido"
                value={{mensagem: "Olá, desejo antecipar minha entrega!"}}
                width="100%"
                height="47px"
                corPrimaria={this.props.corPrimaria}
            />
          </Div>

          {horariosSplit.map((horarios, index) => {
            return (
                <Div inline="center">
                  {Array.from(horarios).map((horario, key) => {
                    let isSelected =
                        horarioSelecionado.descricao === horario.descricao &&
                        horarioSelecionado.dataEntrega === horario.dataEntrega;
                    return (
                        <Div
                            column
                            style={{
                              color: isSelected ? "white" : Color.ECOMMERCE.BLACK_GRAY,
                              backgroundColor: isSelected
                                  ? this.props.corPrimaria
                                  : Color.ECOMMERCE.CINZA,
                              padding: 21,
                              borderRadius: 5,
                              height: 90,
                              opacity: 1,
                              margin: 5,
                              justifyContent: "center",
                              fontSize: 14,
                              lineHeight: 0.8,
                            }}
                            pointer
                            handlerClick={() =>
                                this.setState({outroHorarioSelecionado: horario}, () =>
                                    this.renderSelecionarOutroHorario(todosHorarios)
                                )
                            }
                        >
                          <Label
                              value={`${horario.dataEntrega}`}
                              family="Bold"
                              pointer
                          />
                          <Label
                              value={`${horario.descricao}`}
                              family="Bold"
                              pointer
                          />
                        </Div>
                    );
                  })}
                </Div>
            );
          })}
        </Div>
    );
  }

  renderSelecionarOutroHorario(horarios) {
    ModalNotification.renderMessage({
      title: "Selecione outro horário",
      styleButtonConfirm: {
        border: "none",
        backgroundColor: this.props.corPrimaria,
      },
      styleTitle: {
        color: "#FFF",
      },
      styleHeader: {
        border: "none",
        backgroundColor: this.props.corPrimaria,
      },
      body: this.getHorariosSelecaoBody(horarios),
      handlerConfirm: () => {
        let outroHorario = this.state.outroHorarioSelecionado;
        Array.from(horarios).map((horario) => (horario.active = false));

        let horarioSelecionado = horarios.filter(
            (h) =>
                h.descricao === outroHorario.descricao &&
                h.dataEntrega === outroHorario.dataEntrega
        )[0];
        if (Utils.isValueValid(horarioSelecionado)) {
          horarioSelecionado.active = !horarioSelecionado.active;
        }

        this.setState(
            {
              horarios: [...this.state.horarios],
            },
            () => this.props.handlerHorarioSelecionado(horarioSelecionado)
        );
      },
      handlerCancel: () => {
      },
    });
  }

  renderHorariosRetiradaNovo() {
    let todosHorarios = this.getHorariosRetirada();
    let vazio = {
      dataEntrega: "Data e Horário",
      descricao: "não disponivel"
    }

    let horarioSelecionado = Utils.isNotNull(todosHorarios) ?
        todosHorarios.filter((h) => h.active)[0] || todosHorarios[0] : vazio ;

    return (
        <Div inline="start" className="w-100" style={{cursor: "pointer", marginTop: 30}}>
          <Label
              value="AGENDE SEU HORÁRIO"
              style={styles.labelInfo}
              family="SemiBold"
          />

          <Div
              row
              style={styles.selecioneHorario}
              handlerClick={() => this.renderSelecionarOutroHorario(todosHorarios)}
          >
            <Div column>
              <Label value="Selecione seu Horário" family="Bold"/>
              <Label
                  value={`${horarioSelecionado.dataEntrega} ${horarioSelecionado.descricao}`}
                  family="Light"
                  style={{fontSize: 22}}
              />
            </Div>
            <I
                pointer
                icon={Type.ICON.ARROW_CHEV_DOWN}
                style={{
                  color: Color.ECOMMERCE.CINZA,
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 7,
                  marginLeft: 10
                }}
            />
          </Div>
        </Div>
    );
  }


  renderHorariosRetirada() {
    let todosHorarios = this.getHorariosRetirada();
    let horarioSelecionado = todosHorarios.filter((h) => h.active)[0] || {};

    return (
        <Div inline="center" className="w-100" marginTop="2">
          <Select
              responsive="12"
              valueSelected={horarioSelecionado.idEmpresaHorarioEntrega}
              handlerChange={(e) => {
                let idEmpresaHorarioEntregaSelecionado = e.target.value;
                Array.from(todosHorarios).map(
                    (horario) => (horario.active = false)
                );

                let horarioSelecionado = todosHorarios.filter(
                    (h) =>
                        h.idEmpresaHorarioEntrega === idEmpresaHorarioEntregaSelecionado
                )[0];

                if (Utils.isValueValid(horarioSelecionado)) {
                  horarioSelecionado.active = !horarioSelecionado.active;
                }

                this.setState(
                    {
                      horarios: [...this.state.horarios],
                    },
                    () => this.props.handlerHorarioSelecionado(horarioSelecionado)
                );
              }}
              values={this.getHorariosRetiradaSelect()}
              style={{
                border: "none",
                borderBottom: `1px solid #f2f2f2`,
                textAlignLast: "center",
              }}
          />
        </Div>
    );
  }

  render() {
    let filiais = this.state.empresa.filiais || [];
    let {pedido} = this.props;

    return (
        <Div col="6" padding="0">
          <Label
              value="SELECIONE UMA DAS LOJAS"
              style={styles.labelInfo}
              family="SemiBold"
              marginTop="2"
          />
          {Array.from(filiais).map((filial, index) => {
            let isFilialSelecionada =
                this.props.filialSelecionadaRetirada.idFilial === filial.idFilial;
            return (
                <Div
                    key={index}
                    className="d-flex flex-column align-items-start justify-content-start p-2 mt-2 w-100"
                    pointer
                    rounded
                    handlerClick={() => this.props.handlerFilialSelecionada(filial)}
                    style={
                      isFilialSelecionada
                          ? {...styles.filialSelecionada, backgroundColor: this.props.corPrimaria}
                          : styles.filialNaoSelecionada
                    }
                >
                  <If and value1={!isFilialSelecionada}>
                    <Label value={filial.fantasia} family="Bold"/>
                  </If>
                  <If and value1={isFilialSelecionada}>
                    <Div
                        inline="between"
                        className="align-self-start w-100"
                    >
                      <Label value={filial.fantasia} family="Bold"/>
                    </Div>
                  </If>
                  <Label
                      value={`${filial.logradouro} n° ${filial.numero}, ${filial.bairro}`}
                      family="Light"
                      style={{marginBottom: 0}}
                  />
                </Div>
            );
          })}

          {this.renderHorariosRetiradaNovo()}

          <Label
              value="Observações"
              style={styles.labelInfo}
              family="SemiBold"
          />
          <TextArea
              paddingLeft="0"
              paddingRight="0"
              responsive="12"
              handlerChange={(e) => {
                pedido = {
                  ...pedido,
                  obs: e.target.value,
                };
                this.props.handlerPedido(pedido);
              }}
              style={{marginBottom: 20}}
          />
        </Div>
    );
  }
}

export default TemplatePedidoRetirada;
